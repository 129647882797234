import React from "react";

const NumberOfRegisteredUsers = ({ usersCount }) => {
  console.log("count", usersCount);
  return (
    <div className="flex flex-col bg-blue-100 rounded-lg shadow ">
      <div className="flex justify-center bg-gray-600 text-white py-2 text-lg">
        <p>Total Users</p>
      </div>
      <div className="p-5">
        <p className="text-lg font-medium text-gray-400">
          Number of Registered Users
        </p>
        <p className="text-2xl italic">{usersCount}</p>
      </div>
    </div>
  );
};

export default NumberOfRegisteredUsers;
