import firebase from "firebase";
import React, { useContext } from "react";
import { AiFillDashboard } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import Logo from "../ITHUBLOGO.PNG";
import { FirebaseContext } from "../firebase";

const NavBar = () => {
  const { currentUser } = useContext(FirebaseContext);
  let { companyId } = useParams();

  return (
    <div>
      <div className="bg-blue-500 p-1 text-sm text-center text-white">
        <div>
          {currentUser.user.type === "company"
            ? "Employer Dashboard"
            : currentUser.user.type === "admin"
            ? "Admin Dashboard"
            : "Task Dashboard"}
        </div>
      </div>

      <div className="bg-green-50 flex items-center justify-around w-full">
        <div className="container flex items-center justify-between px-4 py-2 lg:px-20">
          <Link
            to={
              currentUser.user.type === "company" ||
              currentUser.user.type === "task"
                ? `/company/${
                    currentUser.company
                      ? currentUser.company.company_id
                      : companyId
                  }/dashboard`
                : currentUser.user.type === "admin"
                ? "/admin/dashboard"
                : null
            }
          >
            <img src={Logo} alt="IT Hub Khammam" className="h-10 w-30" />
          </Link>
          <div className="flex justify-center items-center text-sm gap-5 lg:gap-10">
            <Link
              to={
                currentUser.user.type === "company" ||
                currentUser.user.type === "task"
                  ? `/company/${
                      currentUser.company
                        ? currentUser.company.company_id
                        : companyId
                    }/dashboard`
                  : currentUser.user.type === "admin"
                  ? "/admin/dashboard"
                  : "task/dashboard"
              }
              className="flex justify-center items-center gap-1"
            >
              <AiFillDashboard className="text-lg" />
              <span>Dashboard</span>
            </Link>
            <button
              onClick={() => {
                firebase.auth().signOut();
              }}
              className="flex justify-center items-center gap-1"
            >
              <FiLogOut className="text-lg" />
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
