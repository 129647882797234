import React, { useContext, useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import Loading from "../../../components/Loading";
import { db, FirebaseContext } from "../../../firebase";
import DefaultProfilePic from "../../../PlaceHolder.png";
import Analytics from "./Analytics/index.js";

const CompanyCard = ({ data, setJobStatusChanged }) => {
  //console.log("Post Details", data);
  //const [status, setStatus] = useState(data.status);
  const { currentUser } = useContext(FirebaseContext);

  //   const changeStatus = (status) => {
  //     db.collection(`companies/${currentUser.company.company_id}/jobs`)
  //       .doc(data.id)
  //       .update({
  //         status: status,
  //       })
  //       .then(() => {
  //         toast.success("Job Status changed!");
  //         setJobStatusChanged(Math.random());
  //       })
  //       .catch((error) => {
  //         console.log("Error", error);
  //         toast.error("Failed to update status!");
  //       });
  //   };

  return (
    <Link
      to={`/company/${data.id}/dashboard`}
      className="flex flex-col bg-white p-4 h-52 border rounded-md shadow-md"
    >
      <div className="flex lg:flex-row lg:justify-between items-start flex-col-reverse">
        <div>
          <div className="flex gap-3 items-center">
            <p className="text-base font-semibold text-gray-700 mb-1 flex-nowrap">
              {data.name}
            </p>
            {/* <div>
              <div
                className={`${
                  data.status
                    ? "bg-green-100 text-green-500"
                    : "bg-red-100 text-red-600"
                } px-2 rounded-xl`}
              >
                <p className="text-sm">{data.status ? "Open" : "Closed"}</p>
              </div>
            </div> */}
          </div>
          <p className="text-sm font-medium text-gray-400">
            {data.email ? data.email : "N/A"}
          </p>
        </div>
        <div>
          <img
            src={data.profile_pic ? data.profile_pic : DefaultProfilePic}
            alt="Company Logo"
            height="100"
            width="100"
            className="rounded-lg"
          ></img>
        </div>
      </div>

      {/* <div className="flex items-center pt-5 gap-5 lg:gap-10">
        <div>
          <div className="flex items-center gap-2  text-gray-500 text-xs">
            <AiOutlinePlayCircle />
            STARTS
          </div>
          <p className="text-xs lg:text-base">
            {`${format(new Date(data.startsAt.seconds * 1000), "P")}`}
          </p>
        </div>
        <div>
          <div className="flex items-center gap-2 text-gray-500 text-xs">
            <FaRegMoneyBillAlt />
            STIPEND
          </div>
          <p className="text-xs lg:text-base">
            ₹ {data.minCTC}-{data.maxCTC} LPA
          </p>
        </div>
        <div>
          <div className="flex items-center gap-2 text-gray-500 text-xs">
            <AiOutlineHourglass />
            APPLY BY
          </div>
          <p className="text-xs lg:text-base">
            {`${format(new Date(data.endsAt.seconds * 1000), "P")}`}
          </p>
        </div>
      </div> */}

      <div className="flex items-center justify-between mt-6">
        {/* <div className="flex gap-5 items-center">
          <ViewApplicants
            company_id={data.company_id}
            jobPostId={data.id}
            jobTitle={data.jobTitle}
          />
        </div> */}

        {/* <div className="flex gap-3">
          <Link
            to={`/company/job/${data.id}/edit`}
            className=" py-2 bg-blue-100 px-4 rounded-md flex items-center gap-2 text-sm"
          >
            <MdEdit className="text-xl text-blue-600" />
          </Link>
          <Link
            to={`/company/${data.id}/details`}
            className=" py-2 bg-blue-100 px-4 rounded-md flex items-center gap-2 text-sm"
          >
            <BsFillEyeFill className="text-xl text-blue-600" />
          </Link>
        </div> */}
      </div>

      {/* <div className="flex gap-10 items-center mt-5">
        <p className="text-base font-medium">Job Status :</p>
        <div className="flex items-center gap-2">
          <p className="text-red-500 text-sm lg:text-lg font-medium">Close</p>
          <Switch
            type="checkbox"
            name="status"
            onChange={(checked) => {
              setStatus(!status);
              console.log("Checked", checked);
              changeStatus(checked);
            }}
            checked={status}
          />
          <p className="text-green-500 text-sm lg:text-lg font-medium">Open</p>
        </div>
      </div> */}
    </Link>
  );
};

const Dashboard = () => {
  //   const [jobStatusChanged, setJobStatusChanged] = useState(null);
  const [companies, setcompanies] = useState(null);

  const fetchCompanies = async () => {
    let data = await db
      .collection(`companies`)
      .get()
      .catch((error) => {
        console.log("Unable to fetch companies!", error);
        setcompanies([]);
      });

    if (data && data.docs && data.docs.length > 0) {
      let arr = data.docs.map((company) => {
        return { ...company.data(), id: company.id };
      });
      console.log("Arr", arr);
      setcompanies(arr);
    } else {
      setcompanies([]);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  return (
    <div className="bg-gray-50">
      <Header view="dashboard" type="admin" />

      <div className="flex flex-col items-center justify-around w-full">
        <div className="container px-3 py-10 lg:px-20 lg:py-10">
          <Analytics />
          <div className="flex lg:flex-row flex-col-reverse gap-10 lg:gap-0 lg:justify-between items-center pb-5">
            <p className="text-lg lg:text-xl font-semibold">Companies</p>
            <div className="flex flex-col lg:flex-row items-center justify-center gap-3">
              <Link
                to="/admin/createCompany"
                className="flex gap-1 items-center justify-center px-3 py-2 bg-blue-500 text-white rounded-lg focus:outline-none hover:bg-blue-800"
              >
                <MdAddCircleOutline className="text-xl" />
                <p className="text-sm">Create new company</p>
              </Link>
              {/* <Link
                to="/company/event/create"
                className="flex gap-1 items-center justify-center px-3 py-2 bg-green-500 text-white rounded-lg focus:outline-none hover:bg-green-800"
              >
                <MdAddCircleOutline className="text-xl" />
                <p className="text-sm">Create new Event/News</p>
              </Link> */}
            </div>
          </div>

          <div>
            {companies ? (
              companies.length > 0 ? (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 pb-5 ">
                  {companies.map((company, index) => (
                    <div key={company.id}>
                      <CompanyCard
                        data={company}

                        //setJobStatusChanged={setJobStatusChanged}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex justify-center m-10">
                  <p>No companies available!</p>
                </div>
              )
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
