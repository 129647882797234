import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../../../firebase";
import Loading from "../../../../components/Loading";
import NumberOfRegisteredUsers from "./NumberOfRegisteredUsers";
import MaleFemale from "./MaleFemale";
import Category from "./Category";
import Qualification from "./Qualification";

const Analytics = () => {
  const [fetchUsersError, setFetchUsersError] = useState(false);
  const [users, setUsers] = useState(null);

  const fetchUsers = async () => {
    let data = await db
      .collection("users")
      .get()
      .catch((error) => {
        console.log(error);
        setFetchUsersError(true);
        toast.error("Failed to fetch data!");
      });

    if (data && data.docs) {
      if (data.docs.length > 0) {
        setUsers(
          data.docs.map((user) => {
            //console.log(user.data().qualification);
            return {
              ...user.data(),
              id: user.id,
            };
          })
        );
      } else {
        setUsers([]);
      }
    } else {
      setFetchUsersError(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  if (fetchUsersError) {
    return (
      <div className="flex justify-center m-10">
        <p>No analytics available!...</p>
      </div>
    );
  }

  if (!users) {
    return <Loading />;
  }

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-3 mb-10 gap-5">
        <NumberOfRegisteredUsers usersCount={users.length} />
        <MaleFemale data={users} />
        <Category data={users} />
      </div>
      <div className="hidden lg:block">
        <Qualification data={users} />
      </div>
    </div>
  );
};

export default Analytics;
