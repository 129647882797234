import React, { useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import Loading from "../../../components/Loading";
import { db } from "../../../firebase";
import { BsTrash } from "react-icons/bs";
import AddNewManagerModal from "./AddNewManagerModal";
import DeleteModal from "../../../components/DeleteModal";

const ManagerItem = ({ data, setManagerDeleted }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  let { companyId } = useParams();
  console.log("Manager Data", data);
  return (
    <div className="flex flex-col bg-white p-4 border rounded-md shadow-md">
      <div className="flex flex-row justify-between items-center">
        <div>
          <div className="flex gap-3 items-center">
            <p className="text-base font-semibold text-gray-700 mb-1 flex-nowrap">
              {data.name ? data.name : "N/A"}
            </p>
          </div>
          <p className="text-sm font-medium text-gray-400">
            {data.email ? data.email : "N/A"}
          </p>
        </div>
        <div>
          <BsTrash
            onClick={() => setIsModalOpen(true)}
            className="text-xl text-red-500 cursor-pointer"
          />
        </div>
      </div>

      {isModalOpen ? (
        <DeleteModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          headerText={"Delete manager"}
          confirmationText="Are you sure to delete the manager?"
          companyId={companyId}
          type="manager"
          managerId={data.id}
          setManagerDeleted={setManagerDeleted}
        />
      ) : null}
    </div>
  );
};

const CreateEditManagers = () => {
  let { companyId } = useParams();
  const [managersDetails, setManagersDetails] = useState(null);
  const [managersDetailsError, setManagersDetailsError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [managerDeleted, setManagerDeleted] = useState(null);
  const [newManagerAdded, setNewManagerAdded] = useState(null);

  const fetchManagers = async () => {
    let data = await db
      .collection(`companies/${companyId}/managers`)
      .get()
      .catch((error) => {
        console.log(error);
        setManagersDetailsError(true);
        toast.error("Error while fetching managers info!");
      });

    if (data && data.docs && data.docs.length > 0) {
      setManagersDetails(
        data.docs.map((manager) => {
          return {
            ...manager.data(),
            id: manager.id,
          };
        })
      );
    } else {
      setManagersDetails([]);
    }
  };

  useEffect(() => {
    fetchManagers();
  }, [managerDeleted, newManagerAdded]);

  if (managersDetailsError) {
    return (
      <div className="flex justify-center m-10">
        <p>Failed to fetch managers data...</p>
      </div>
    );
  }

  if (!managersDetails) {
    return <Loading />;
  }

  return (
    <div>
      <Header view="managers" />

      <div className="container lg:px-20 p-5 flex flex-col gap-10 ">
        <div className="flex flex-col-reverse lg:flex-row gap-5  justify-between items-center">
          <p className="text-xl font-medium">Manage Managers</p>
          <button
            onClick={() => setIsModalOpen(true)}
            className="flex gap-1 items-center justify-center px-3 py-2 bg-blue-500 text-white rounded-lg focus:outline-none hover:bg-blue-800"
          >
            <MdAddCircleOutline className="text-xl" />
            <p className="text-sm">Add new manager</p>
          </button>
        </div>

        {isModalOpen ? (
          <AddNewManagerModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setNewManagerAdded={setNewManagerAdded}
          />
        ) : null}

        {managersDetails && managersDetails.length !== 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            {managersDetails.map((manager) => {
              return (
                <div>
                  <ManagerItem
                    data={manager}
                    setManagerDeleted={setManagerDeleted}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex justify-center m-10">
            <p>No managers found!...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateEditManagers;
