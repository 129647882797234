import React, { useContext } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Company from "./pages/Company";
import { FirebaseContext } from "./firebase";
import LandingPage from "./pages/Login/LandingPage";
import Login from "./pages/Login";
import ITHub from "./pages/ITHub";
import ITHubGallery from "./pages/ITHub/ITHubGallery";
import Admin from "./pages/Admin";

function App() {
  const { currentUser } = useContext(FirebaseContext);

  if (!currentUser) {
    console.log("Current App User", currentUser);
    return (
      <Switch>
        <Route path="/login" exact render={() => <Login />} />
        <Route path="/" exact render={() => <LandingPage />} />
        <Route path="/ITHubGallery" render={() => <ITHubGallery />} />

        <Route path="/ITHub" component={() => <ITHub />} />
        <Redirect to="/" />
      </Switch>
    );
  }

  if (currentUser.user.type === "company" || currentUser.user.type === "task") {
    return (
      <Switch>
        <Route path="/company" component={() => <Company />} />
        <Route path="/ITHubGallery" exact render={() => <ITHubGallery />} />

        <Route path="/ITHub" component={() => <ITHub />} />

        <Redirect
          path="/"
          to={`/company/${currentUser.company.company_id}/dashboard/`}
        />
      </Switch>
    );
  }

  if (currentUser.user.type === "admin") {
    return (
      <Switch>
        <Route path="/admin" component={() => <Admin />} />
        <Route path="/company" component={() => <Company />} />
        <Route path="/ITHubGallery" exact render={() => <ITHubGallery />} />

        <Route path="/ITHub" component={() => <ITHub />} />

        <Redirect path="/" to="/admin/dashboard" />
      </Switch>
    );
  }

  return null;
}

export default App;
