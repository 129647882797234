import React, { useState, useContext, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import { db, FirebaseContext } from "../../../firebase";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import RequiredAsterisk from "../../../components/RequiredAsterisk";

const schema = Yup.object().shape({
  newsTitle: Yup.string().nullable().required("Required!"),
  newsDescription: Yup.string().nullable().required("Required!"),
});

const CreateEditNews = () => {
  let { newsId } = useParams();
  const [newsDetails, setnewsDetails] = useState();
  const { currentUser } = useContext(FirebaseContext);
  const [newsAdded, setnewsAdded] = useState(false);
  const [addingNewNews, setaddingNewNews] = useState(false);
  const [newsFetchError, setNewsFetchError] = useState(false);
  let { companyId } = useParams();
  const [companyDetails, setCompanyDetails] = useState(null);

  const fetchNewsFromCompany = async () => {
    let data = await db
      .collection(`companies/${companyId}/news`)
      .doc(newsId)
      .get()
      .catch((error) => {
        console.log(error);
        setNewsFetchError(true);
        toast.error("Failed to fetch news!");
      });

    if (data && data.data()) {
      //console.log("News Data", data.data());
      setnewsDetails(data.data());
    }
  };

  const fetchCompanyDetails = async () => {
    let data = await db
      .collection(`companies`)
      .doc(companyId)
      .get()
      .catch((error) => {
        console.log(error);
      });

    if (data && data.data()) {
      setCompanyDetails(data.data());
    }
  };

  useEffect(() => {
    if (newsId) {
      fetchNewsFromCompany();
    }
    fetchCompanyDetails();
  }, []);

  if (newsFetchError) {
    return (
      <div className="flex justify-center m-10 text-xl text-red-500">
        <p>Failed to fetch news details!...</p>
      </div>
    );
  }

  return (
    <div>
      {newsId ? <Header view="editNews" /> : null}

      {!newsId || newsDetails ? (
        <div className="lg:px-28 lg:pt-10">
          <Formik
            initialValues={
              newsDetails
                ? {
                    newsTitle: newsDetails.newsTitle,
                    newsDescription: newsDetails.newsDescription,
                  }
                : {
                    newsTitle: null,
                    newsDescription: null,
                  }
            }
            validationSchema={schema}
            onSubmit={(values) => {
              console.log("Values", newsId);
              let finalData = {
                ...values,
                company_id: companyId,
                company_name: companyDetails.name,
                profile_pic: companyDetails.profile_pic
                  ? companyDetails.profile_pic
                  : "",
                aboutUs: companyDetails.aboutUs ? companyDetails.aboutUs : "",
                time: new Date(),
                status: false,
              };
              if (!newsId) {
                if (companyDetails) {
                  setaddingNewNews(true);
                  db.collection(`companies/${companyId}/news`)
                    .add(finalData)
                    .then(() => {
                      toast.success("News created successfully!");
                      setaddingNewNews(false);
                      setnewsAdded(true);
                    })
                    .catch((error) => {
                      console.log(error);
                      setaddingNewNews(false);

                      toast.error("Error occured while creating news!");
                    });
                } else {
                  toast.error("Waiting for company details!...");
                }
              } else {
                if (companyDetails) {
                  setaddingNewNews(true);
                  db.collection(`companies/${companyId}/news`)
                    .doc(newsId)
                    .update(finalData)
                    .then(() => {
                      toast.success("News edited successfully!");
                      setaddingNewNews(false);
                    })
                    .catch((error) => {
                      console.log(error);
                      setaddingNewNews(false);
                      toast.error("Error occured while editing news!");
                    });
                } else {
                  toast.error("Waiting for company details!...");
                }
              }
            }}
          >
            {({ values, errors, setFieldValue }) => {
              return (
                <Form className="lg:w-10/12 mx-auto px-2 py-5 lg:px-0 lg:py-0">
                  <div className="flex flex-col gap-10">
                    <div>
                      <p className="text-xl font-medium">News details</p>
                      <div className="p-5 mt-2 flex flex-col gap-4 rounded-lg border">
                        <div>
                          <div className="flex gap-1 items-center">
                            <p>News title</p>

                            <RequiredAsterisk />
                          </div>

                          <Field
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="Title of the news"
                            name="newsTitle"
                          />
                          <ErrorMessage
                            name="newsTitle"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>

                        <div>
                          <div className="flex gap-1 items-center">
                            <p>News description</p>

                            <RequiredAsterisk />
                          </div>

                          <Field
                            as="textarea"
                            rows={6}
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="Write something about the news..."
                            name="newsDescription"
                          />
                          <ErrorMessage
                            name="newsDescription"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pb-5 flex justify-end">
                      {!newsId ? (
                        !newsAdded ? (
                          <button
                            type="submit"
                            disabled={addingNewNews}
                            className={`px-6 py-2 bg-blue-600 rounded-lg text-white focus:outline-none`}
                          >
                            {addingNewNews ? (
                              <div className="flex gap-2 items-center justify-center">
                                <AiOutlineLoading3Quarters className=" animate-spin" />
                                <p>Submitting...</p>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        ) : (
                          <div className="px-6 py-2 bg-green-300 rounded-lg text-white focus:outline-none">
                            <p>News Created</p>
                          </div>
                        )
                      ) : (
                        <button
                          type="submit"
                          disabled={addingNewNews}
                          className={`px-6 py-2 bg-blue-600 rounded-lg text-white focus:outline-none`}
                        >
                          {addingNewNews ? (
                            <div className="flex gap-2 items-center justify-center">
                              <AiOutlineLoading3Quarters className=" animate-spin" />
                              <p>Submitting...</p>
                            </div>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default CreateEditNews;
