import React from "react";
import { useParams } from "react-router-dom";
import Header from "../../../components/Header";
import ApplicantsEnrollments from "./ApplicantsEnrollments";
import StatusNavigation from "./StatusNavigation";

const ApplicantsInfo = () => {
  let { jobPostId, courseId } = useParams();
  return (
    <div>
      <Header
        view="applicants"
        type={jobPostId ? "company" : courseId ? "task" : null}
      />
      <StatusNavigation />
      <ApplicantsEnrollments />
    </div>
  );
};

export default ApplicantsInfo;
