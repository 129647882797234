import React, { useState, useEffect } from "react";
import Loading from "../../../../components/Loading";

const Category = ({ data }) => {
  const [qualifications, setQualifications] = useState(null);
  useEffect(() => {
    let temp = {
      diploma: {
        name: "Diploma/ITI",
        count: 0,
      },
      secondarySchool: {
        name: "Secondary school",
        count: 0,
      },
      higherSecondarySchool: {
        name: "Higher secondary school",
        count: 0,
      },
      intermediate: {
        name: "Intermediate",
        count: 0,
      },
      bachelors: {
        name: "Bachelors/Honors",
        count: 0,
      },
      masters: {
        name: "Masters",
        count: 0,
      },
      bachelorOfEngineeringTech: {
        name: "Bachelor of Engineering/Tech",
        count: 0,
      },
      bachelorOfArts: {
        name: "Bachelor of Arts",
        count: 0,
      },
      bachelorOfScience: {
        name: "Bachelor of Science",
        count: 0,
      },
      bachelorOfCommerce: {
        name: "Bachelor of Commerce",
        count: 0,
      },
      BMSBBABBS: {
        name: "BMS/BBA/BBS",
        count: 0,
      },
      bachelorOfLaw: {
        name: "Bachelor of Law",
        count: 0,
      },
      bachelorOfMedicine: {
        name: "Bachelor of Medicine(MBBS)",
        count: 0,
      },
      MBA: {
        name: "Master of Business Administration (MBA)",
        count: 0,
      },
      PG: {
        name: "P.G",
        count: 0,
      },
      others: {
        name: "Others",
        count: 0,
      },
    };
    for (let i = 0; i < data.length; i++) {
      if (data[i].qualification === "Bachelor of Engineering/Tech") {
        temp.bachelorOfEngineeringTech.count++;
      } else if (data[i].qualification === "Masters") {
        temp.masters.count++;
      } else if (
        data[i].qualification === "Master of Business Administration (MBA)"
      ) {
        temp.MBA.count++;
      } else if (data[i].qualification === "Intermediate") {
        temp.intermediate.count++;
      } else if (data[i].qualification === "Diploma/ITI") {
        temp.diploma.count++;
      } else if (data[i].qualification === "Others") {
        temp.others.count++;
      } else if (data[i].qualification === "Bachelor of Commerce") {
        temp.bachelorOfCommerce.count++;
      } else if (data[i].qualification === "Bachelor of Science") {
        temp.bachelorOfScience.count++;
      } else if (data[i].qualification === "Bachelor of Arts") {
        temp.bachelorOfArts.count++;
      } else if (data[i].qualification === "Bachelors/Honors") {
        temp.bachelors.count++;
      } else if (data[i].qualification === "P.G") {
        temp.PG.count++;
      } else if (data[i].qualification === "Higher secondary school") {
        temp.higherSecondarySchool.count++;
      } else if (data[i].qualification === "Secondary school") {
        temp.others.count++;
      } else if (data[i].qualification === "BMS/BBA/BBS") {
        temp.BMSBBABBS.count++;
      } else if (data[i].qualification === "Bachelor of Law") {
        temp.bachelorOfLaw.count++;
      } else if (data[i].qualification === "Bachelor of Medicine(MBBS)") {
        temp.bachelorOfMedicine.count++;
      }
    }
    console.log(temp);
    setQualifications(temp);
  }, []);

  //console.log("count", maleCount, femaleCount);
  return (
    <div className="flex flex-col bg-blue-100 rounded-lg shadow mb-5">
      <div className="flex justify-center bg-gray-600 text-white py-2 text-lg">
        <p>Education Qualification</p>
      </div>
      <div className="grid grid-cols-4 gap-7 p-5">
        {qualifications ? (
          Object.keys(qualifications).map((qualification) => {
            console.log(qualification, qualifications[qualification]);
            return (
              <div className="flex justify-center flex-col border border-black px-5 py-2 rounded-lg">
                <p className="text-lg font-medium text-gray-400">
                  {qualifications[qualification].name}
                </p>
                <p className="text-2xl italic">
                  {qualifications[qualification].count}
                </p>
              </div>
            );
          })
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Category;
