import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../components/Header";
import Loading from "../../../components/Loading";
import { db, FirebaseContext } from "../../../firebase";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { isAfter } from "date-fns";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toast } from "react-toastify";
import ImageDropzone from "../../../components/ImageDropZone";
import RequiredAsterisk from "../../../components/RequiredAsterisk";

const schema = Yup.lazy((values) => {
  return Yup.object().shape({
    eventTitle: Yup.string().nullable().required("Required!"),
    eventDescription: Yup.string().nullable().required("Required!"),
    startsAt: Yup.date().nullable().required("Required!"),
    endsAt: Yup.date()
      .nullable()
      .required("Required!")
      .test(
        "greaterThanEventStartAt",
        "Date should be greater than start date",
        (value) => {
          if (value && values.startsAt) return isAfter(value, values.startsAt);
          return true;
        }
      ),
  });
});

const CreateEditEvent = () => {
  const [eventDetails, setEventDetails] = useState(null);
  const { currentUser } = useContext(FirebaseContext);
  let { eventId } = useParams();
  const [eventDetailsError, setEventDetailsError] = useState(false);
  const [addingNewEvent, setAddingNewEvent] = useState(false);
  const [eventAdded, setEventAdded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  let { companyId } = useParams();
  const [companyDetails, setCompanyDetails] = useState(null);

  const fetchEventDetails = async () => {
    let data = await db
      .collection(`companies/${companyId}/events`)
      .doc(eventId)
      .get()
      .catch(() => {
        toast.error("Error while fetching Event Info!");
      });

    if (data && data.data()) {
      setEventDetails(data.data());
    } else {
      toast.error("Event Data not available");
      setEventDetailsError(true);
    }
  };

  const fetchCompanyDetails = async () => {
    let data = await db
      .collection(`companies`)
      .doc(companyId)
      .get()
      .catch((error) => {
        console.log(error);
      });

    if (data && data.data()) {
      setCompanyDetails(data.data());
    }
  };

  useEffect(() => {
    if (eventId) {
      fetchEventDetails();
    }
    fetchCompanyDetails();
  }, []);

  if (eventDetailsError) {
    return (
      <div className="flex justify-center m-10">
        <p>Failed to load event details...</p>
      </div>
    );
  }

  return (
    <div>
      <div>{eventId ? <Header view="editEvent" /> : null}</div>

      {!eventId || eventDetails ? (
        <div className="lg:px-28 lg:pt-10">
          <Formik
            initialValues={
              eventDetails
                ? {
                    //status: eventDetails.status,
                    eventTitle: eventDetails.eventTitle,
                    eventDescription: eventDetails.eventDescription,
                    startsAt: new Date(eventDetails.startsAt.seconds * 1000),
                    endsAt: new Date(eventDetails.endsAt.seconds * 1000),
                    registrationLink: eventDetails.registrationLink,
                    eventLogo: eventDetails.eventLogo,
                  }
                : {
                    //status: eventDetails.status,
                    eventTitle: null,
                    eventDescription: null,
                    startsAt: null,
                    endsAt: null,
                    registrationLink: "",
                    eventLogo: null,
                  }
            }
            validationSchema={schema}
            onSubmit={(values) => {
              console.log("Values", values);
              let finalData = {
                ...values,
                company_id: companyId,
                company_name: companyDetails.name,
                profile_pic: companyDetails.profile_pic
                  ? companyDetails.profile_pic
                  : "",
                aboutUs: companyDetails.aboutUs ? companyDetails.aboutUs : "",
                time: new Date(),
                status: false,
              };

              //console.log("Final Data", finalData);
              if (!eventId) {
                if (companyDetails) {
                  setAddingNewEvent(true);
                  db.collection(`companies/${companyId}/events`)
                    .add(finalData)
                    .then(() => {
                      toast.success("New event created successfully!");
                      setAddingNewEvent(false);
                      setEventAdded(true);
                    })
                    .catch((error) => {
                      console.log(error);
                      setAddingNewEvent(false);

                      toast.error("Error occured while creating new event!");
                    });
                } else {
                  toast.error("Waiting for company details!...");
                }
              } else {
                if (companyDetails) {
                  setAddingNewEvent(true);
                  db.collection(`companies/${companyId}/events`)
                    .doc(eventId)
                    .update(finalData)
                    .then(() => {
                      toast.success("Event edited successfully!");
                      setAddingNewEvent(false);
                    })
                    .catch((error) => {
                      console.log(error);
                      setAddingNewEvent(false);

                      toast.error("Error occured while editing event!");
                    });
                } else {
                  toast.error("Waiting for company details!...");
                }
              }
            }}
          >
            {({ values, errors, setFieldValue }) => {
              return (
                <Form className="lg:w-10/12 mx-auto px-2 py-5 lg:px-0 lg:py-0">
                  {/* <div className="flex justify-end items-center gap-4">
                    <p className="font-medium text-lg">Event Status</p>
                    <Switch
                      type="checkbox"
                      name="status"
                      onChange={(checked) => {
                        setFieldValue("status", checked ? true : false);
                      }}
                      checked={values.status}
                    />
                  </div> */}
                  <div className="flex flex-col gap-10">
                    <div>
                      <p className="text-xl font-medium">Event details</p>
                      <div className="p-5 mt-2 flex flex-col gap-4 rounded-lg border">
                        <div>
                          <div className="flex gap-1 items-center">
                            <p>Event title</p>

                            <RequiredAsterisk />
                          </div>

                          <Field
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="e.g. Workshop on IOT"
                            name="eventTitle"
                          />
                          <ErrorMessage
                            name="eventTitle"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>

                        <div>
                          <div className="flex gap-1 items-center">
                            <p>Event description</p>

                            <RequiredAsterisk />
                          </div>
                          <Field
                            as="textarea"
                            rows={6}
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="Write something about the event..."
                            name="eventDescription"
                          />
                          <ErrorMessage
                            name="eventDescription"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between lg:gap-10">
                          <div className="w-full">
                            <div className="flex gap-1 items-center">
                              <p>Event Start Date</p>

                              <RequiredAsterisk />
                            </div>
                            <Field
                              name="startsAt"
                              className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                              component={(props) => (
                                <CustomDatePicker
                                  {...props}
                                  format="do MMMM, yyyy hh:mm a"
                                  showTimeSelect={true}
                                  padded={true}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="startsAt"
                              render={(msg) => (
                                <div className="text-red-600 text-sm">
                                  {msg}
                                </div>
                              )}
                            />
                          </div>

                          <div className="w-full">
                            <div className="flex gap-1 items-center">
                              <p>Event End Date</p>

                              <RequiredAsterisk />
                            </div>
                            <Field
                              name="endsAt"
                              className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                              component={(props) => (
                                <CustomDatePicker
                                  {...props}
                                  format="do MMMM, yyyy hh:mm a"
                                  showTimeSelect={true}
                                  padded={true}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="endsAt"
                              render={(msg) => (
                                <div className="text-red-600 text-sm">
                                  {msg}
                                </div>
                              )}
                            />
                          </div>
                        </div>

                        <div>
                          <p>Event Registration Link</p>
                          <Field
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="Registration Link"
                            name="registrationLink"
                          />
                          <ErrorMessage
                            name="registrationLink"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>

                        <div>
                          <p>Event Cover Photo</p>
                          <div className="flex justify-center">
                            <ImageDropzone
                              image_url={values.eventLogo}
                              name={`eventLogo`}
                              setFieldValue={setFieldValue}
                              setIsUploading={setIsUploading}
                              path={
                                eventId
                                  ? `eventsCoverPics/${eventId}/eventLogo.png`
                                  : `eventsCoverPics/newPics/${new Date()
                                      .getTime()
                                      .toString()}.png`
                              }
                              className="lg:w-1/2 w-full lg:h-60 h-60 flex justify-center items-center cursor-pointer"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pb-5 flex justify-end">
                      {!eventId ? (
                        !eventAdded ? (
                          <button
                            type="submit"
                            disabled={addingNewEvent || isUploading}
                            className={`px-6 py-2 ${
                              !isUploading
                                ? "bg-blue-600"
                                : "bg-gray-500 text-white cursor-wait"
                            } rounded-lg text-white focus:outline-none`}
                          >
                            {addingNewEvent ? (
                              <div className="flex gap-2 items-center justify-center">
                                <AiOutlineLoading3Quarters className=" animate-spin" />
                                <p>Submitting...</p>
                              </div>
                            ) : isUploading ? (
                              <div className="flex gap-2 items-center justify-center">
                                <AiOutlineLoading3Quarters className=" animate-spin" />
                                <p>Please wait...</p>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        ) : (
                          <div className="px-6 py-2 bg-green-300 rounded-lg text-white focus:outline-none">
                            <p>Event Created</p>
                          </div>
                        )
                      ) : (
                        <button
                          type="submit"
                          disabled={addingNewEvent}
                          className={`px-6 py-2 ${
                            !isUploading
                              ? "bg-blue-600"
                              : "bg-gray-500 text-white cursor-wait"
                          } rounded-lg text-white focus:outline-none`}
                        >
                          {addingNewEvent ? (
                            <div className="flex gap-2 items-center justify-center">
                              <AiOutlineLoading3Quarters className=" animate-spin" />
                              <p>Submitting...</p>
                            </div>
                          ) : isUploading ? (
                            <div className="flex gap-2 items-center justify-center">
                              <AiOutlineLoading3Quarters className=" animate-spin" />
                              <p>Please wait...</p>
                            </div>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default CreateEditEvent;
