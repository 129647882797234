import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import { db } from "../../firebase";
import PlaceHolder from "../../PlaceHolder.png";
import ImageGallery from "react-image-gallery";
import Gallery from "react-photo-gallery";

const ITHubGallery = () => {
  const [photos, setPhotos] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [galleryFetchError, setGalleryFetchError] = useState(false);

  const fetchIHHubGallery = async () => {
    let data = await db
      .collectionGroup("gallery")
      .get()
      .catch((error) => {
        console.log("IT Hub Gallery fetch failure", error);
        setGalleryFetchError(true);
      });
    if (data && data.docs) {
      if (data.docs.length > 0) {
        setPhotos(
          data.docs.map((pic) => {
            return {
              src: pic.data().url,
              width: 4,
              height: 3,
              name: pic.data().name ? pic.data().name : "N/A",
              original: pic.data().url,
              thumbnail: pic.data().url,
              id: pic.id,
            };
          })
        );

        let arr = [];

        data.docs.map((photo) => {
          if (!arr.includes(photo.data().name)) {
            arr.push(photo.data().name);
          }
        });

        setCompanies(arr);
      } else {
        setPhotos([]);
      }
    } else {
      toast.error("Failed to fetch ITHub Gallery!");
      setGalleryFetchError(true);
    }
  };

  useEffect(() => {
    fetchIHHubGallery();
  }, []);

  if (galleryFetchError) {
    return (
      <div className="flex justify-center m-10">
        <p className="text-xl text-red-500">Failed to fetch gallery!..</p>
      </div>
    );
  }

  if (!photos) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (photos && photos.length === 0) {
    return (
      <div className="flex justify-center m-10">
        <p className="text-xl text-red-500">No photos available!...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col p-2 gap-5 w-full items-center">
      <div className="lg:w-1/2 w-full border border-black p-1">
        <ImageGallery items={photos} />
      </div>

      <div>
        <p className="text-xl">By Companies</p>
      </div>

      {companies ? (
        companies.length > 0 ? (
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-5">
            {companies.map((company) => {
              return (
                <button
                  onClick={() => setSelectedCompany(company)}
                  className="px-3 py-2 bg-purple-500 text-white rounded flex justify-center"
                >
                  <p>{company}</p>
                </button>
              );
            })}
          </div>
        ) : (
          <p>No companies found!...</p>
        )
      ) : (
        <Loading />
      )}

      <div>
        <Gallery photos={photos.filter((el) => el.name === selectedCompany)} />
      </div>
    </div>
  );
};

export default ITHubGallery;
