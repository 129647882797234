import React, { useState, useEffect } from "react";
import Loading from "../../../../components/Loading";

const Category = ({ data }) => {
  const [professionalCount, setProfessionalCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [otherCount, setOtherCount] = useState(0);

  useEffect(() => {
    let professional = 0,
      student = 0,
      other = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].category === "Professional") {
        professional++;
      } else if (data[i].category === "Student") {
        student++;
      } else if (data[i].category === "Other") {
        other++;
      }
    }

    setProfessionalCount(professional);
    setStudentCount(student);
    setOtherCount(other);
  }, []);

  //console.log("count", maleCount, femaleCount);

  if (!professionalCount || !studentCount || !otherCount) {
    return <Loading />;
  }

  return (
    <div className="bg-blue-100 rounded-lg shadow">
      <div className="flex justify-center bg-gray-600 text-white py-2 text-lg">
        <p>Category</p>
      </div>
      <div className="flex justify-between p-5">
        <div className="flex justify-center flex-col">
          <p className="text-lg font-medium text-gray-400">Professionals</p>
          <p className="text-2xl italic">{professionalCount}</p>
        </div>
        <div className="flex justify-center flex-col">
          <p className="text-lg font-medium text-gray-400">Students</p>
          <p className="text-2xl italic">{studentCount}</p>
        </div>
        <div className="flex justify-center flex-col">
          <p className="text-lg font-medium text-gray-400">Others</p>
          <p className="text-2xl italic">{otherCount}</p>
        </div>
      </div>
    </div>
  );
};

export default Category;
