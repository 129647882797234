import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Header from "../../../../components/Header";
import { db } from "../../../../firebase";
import Loading from "../../../../components/Loading";
import ViewCandidateModal from "./ViewCandidateModal";
import { BiFilterAlt } from "react-icons/bi";
import Select from "react-select";
import { TiTick } from "react-icons/ti";
import { MdCancel } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { GiCancel } from "react-icons/gi";

const CandidateCards = ({ user }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div>
      <div
        onClick={() => setIsModalOpen(true)}
        className="flex flex-col gap-5 items-center border p-5 cursor-pointer hover:bg-gray-200"
      >
        <div>
          {user.profile_pic ? (
            <img
              src={user.profile_pic}
              alt="Candidate Profile Pic"
              className="h-32 w-32 rounded-full"
            />
          ) : (
            <div className=" h-32 w-32 flex justify-center bg-gray-500 text-white rounded-full items-center">
              <p className="text-2xl">
                {user.name
                  ? user.name.split(" ")[1]
                    ? `${user.name
                        .split(" ")[0]
                        .slice(0, 1)
                        .toUpperCase()}${user.name
                        .split(" ")[1]
                        .slice(0, 1)
                        .toUpperCase()}`
                    : user.name.split(" ")[0].slice(0, 1).toUpperCase()
                  : "N/A"}
              </p>
            </div>
          )}
        </div>
        <p className="bg-red-600 text-white px-3 -mt-10 rounded-lg">
          {user.category}
        </p>

        <div className="flex flex-col items-center">
          <p className="text-lg font-medium">
            {user.name ? user.name.toUpperCase() : "N/A"}
          </p>
          <p className="text-sm font-light">
            {user.email ? user.email : "N/A"}
          </p>
          <p className="text-xs font-light">
            {user.qualification ? user.qualification : "N/A"}
          </p>
        </div>
      </div>
      {isModalOpen ? (
        <ViewCandidateModal
          data={user}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </div>
  );
};

const TalentPool = () => {
  const [fetchUsersError, setFetchUsersError] = useState(false);
  const [users, setUsers] = useState(null);
  const [gender, setGender] = useState([]);
  const [category, setCategory] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchedCandidate, setSearchedCandidate] = useState("");

  const fetchUsers = async () => {
    let data = await db
      .collection("users")
      .get()
      .catch((error) => {
        console.log(error);
        setFetchUsersError(true);
        toast.error("Failed to fetch data!");
      });

    if (data && data.docs) {
      if (data.docs.length > 0) {
        setUsers(
          data.docs.map((user) => {
            // console.log(user.data().qualification);
            return {
              ...user.data(),
              id: user.id,
            };
          })
        );
      } else {
        setUsers([]);
      }
    } else {
      setFetchUsersError(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const categoryOptions = [
    { value: "Student", label: "Student" },
    { value: "Professional", label: "Professional" },
    { value: "Other", label: "Other" },
  ];

  const qualificationOptions = [
    { value: "Diploma/ITI", label: "Diploma/ITI" },
    { value: "Secondary school", label: "Secondary school" },
    { value: "Higher secondary school", label: "Higher secondary school" },
    { value: "Intermediate", label: "Intermediate" },
    { value: "Bachelors/Honors", label: "Bachelors/Honors" },
    { value: "Masters", label: "Masters" },
    {
      value: "Bachelor of Engineering/Tech",
      label: "Bachelor of Engineering/Tech",
    },
    { value: "Bachelor of Arts", label: "Bachelor of Arts" },
    { value: "Bachelor of Science", label: "Bachelor of Science" },
    { value: "Bachelor of Commerce", label: "Bachelor of Commerce" },
    { value: "BMS/BBA/BBS", label: "BMS/BBA/BBS" },
    { value: "Bachelor of Law", label: "Bachelor of Law" },
    {
      value: "Bachelor of Medicine(MBBS)",
      label: "Bachelor of Medicine(MBBS)",
    },
    {
      value: "Master of Business Administration (MBA)",
      label: "Master of Business Administration (MBA)",
    },
    { value: "P.G", label: "P.G" },
    { value: "Other", label: "Other" },
  ];

  if (fetchUsersError) {
    return (
      <div className="flex justify-center m-10">
        <p>No analytics available!...</p>
      </div>
    );
  }

  if (!users) {
    return <Loading />;
  }

  let filteredUsers = users.filter(
    (el) =>
      (qualification && qualification.length > 0
        ? qualification.map((item) => item.value).includes(el.qualification)
        : true) &&
      (gender && gender.length > 0
        ? gender.map((item) => item.value).includes(el.gender)
        : true) &&
      (category && category.length > 0
        ? category.map((item) => item.value).includes(el.category)
        : true)
  );

  const searchedCandidates = filteredUsers.filter((user) =>
    Object.values(user).some(
      (item) => typeof item === "string" && item.includes(searchedCandidate)
    )
  );

  let filtersList = [
    ...(gender ? gender : []),
    ...(qualification ? qualification : []),
    ...(category ? category : []),
  ];

  const removeFilter = (item) => {
    if (category.includes(item)) {
      setCategory(category.filter((el) => el !== item));
    } else if (gender.includes(item)) {
      setGender(gender.filter((el) => el !== item));
    } else if (qualification.includes(item)) {
      setQualification(qualification.filter((el) => el !== item));
    }
  };

  return (
    <div>
      <Header view="talentPool" type="admin" />
      <div className="container px-3 py-10 lg:px-20 lg:py-10">
        <div>
          <div>
            <button
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              className="px-3 py-2 flex items-center gap-1 rounded-lg bg-yellow-400 mb-5 focus:outline-none"
            >
              <BiFilterAlt className="text-xl" />
              <p>Filters</p>
            </button>

            <div
              className={`lg:w-1/4 w-10/12 bg-gray-600 rounded-lg z-40 absolute p-5 ${
                isFilterOpen ? "block" : "hidden"
              }`}
            >
              <div className="flex flex-col gap-5 z-20">
                <div>
                  <p className="text-white my-2">By Category</p>
                  <Select
                    isMulti
                    value={category}
                    onChange={(e) => {
                      if (e) {
                        if (e.length > (category ? category.length : -1)) {
                          toast.success("Filter Applied");
                        }
                      }
                      setCategory(e);
                    }}
                    placeholder="Select filter..."
                    options={categoryOptions}
                  />
                </div>
                <div>
                  <p className="text-white my-2">By Qualification</p>
                  <Select
                    isMulti
                    value={qualification}
                    onChange={(e) => {
                      if (e) {
                        if (
                          e.length > (qualification ? qualification.length : -1)
                        ) {
                          toast.success("Filter Applied");
                        }
                      }
                      setQualification(e);
                    }}
                    placeholder="Select filter..."
                    options={qualificationOptions}
                  />
                </div>
                <div>
                  <p className="text-white my-2">By Gender</p>
                  <Select
                    isMulti
                    value={gender}
                    onChange={(e) => {
                      if (e) {
                        if (e.length > (gender ? gender.length : -1)) {
                          toast.success("Filter Applied");
                        }
                      }
                      setGender(e);
                    }}
                    placeholder="Select filter..."
                    options={genderOptions}
                  />
                </div>
              </div>

              <div className="flex justify-end items-center gap-5 my-5">
                <button
                  onClick={() => setIsFilterOpen(false)}
                  className="flex items-center px-3 py-2 gap-1 rounded-lg bg-red-200 border border-red-600 focus:outline-none "
                >
                  <MdCancel className="text-2xl text-red-600" />
                  <p>Close</p>
                </button>
              </div>
            </div>
          </div>

          {filtersList && filtersList.length > 0 ? (
            <div>
              <p>Applied Filters:</p>
              <div className="flex gap-3 flex-wrap">
                {filtersList.map((filter) => {
                  return (
                    <div className="flex items-center gap-3 px-3 py-2 bg-yellow-200 rounded">
                      <p>{filter.value}</p>
                      <GiCancel
                        onClick={() => removeFilter(filter)}
                        className="text-red-600 text-lg cursor-pointer"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>

        <div className="flex items-center my-5">
          <BiSearch className="text-xl absolute ml-1 text-gray-500" />
          <input
            type="text"
            value={searchedCandidate}
            onChange={(e) => {
              setSearchedCandidate(e.target.value);
            }}
            onFocus={() => (isFilterOpen ? setIsFilterOpen(false) : null)}
            className="w-full px-6 py-2 bg-gray-100 rounded border-2"
            placeholder="Search for candidate..."
          />
        </div>
        <div>
          {searchedCandidates && searchedCandidates.length > 0 ? (
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-10">
              {searchedCandidates.map((user) => {
                //console.log(user.category);
                return <CandidateCards user={user} />;
              })}
            </div>
          ) : (
            <div className="flex justify-center m-10">
              <p>No candidates found...</p>
            </div>
          )}
        </div>
        {/* <div>
          {searchedCandidates && searchedCandidates.length > 0 ? (
            <div>
              {searchedCandidates.length > 0 ? (
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-10">
                  {searchedCandidates.map((user) => {
                    //console.log(user.category);
                    return <CandidateCards user={user} />;
                  })}
                </div>
              ) : (
                <div className="flex justify-center m-10">
                  <p>No candidates found...</p>
                </div>
              )}
            </div>
          ) : null}
        </div> */}
        {/* <div>
          {filteredUsers.length > 0 ? (
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-10">
              {filteredUsers.map((user) => {
                //console.log(user.category);
                return <CandidateCards user={user} />;
              })}
            </div>
          ) : (
            <div className="flex justify-center m-10">
              <p>No candidates found...</p>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default TalentPool;
