import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import { db } from "../firebase";
import { AiOutlinePlayCircle, AiOutlineHourglass } from "react-icons/ai";
import { format } from "date-fns";
import { toast } from "react-toastify";
import Switch from "react-switch";

const EventCard = ({
  data,
  viewFromITHUB,
  company_id,
  setEventStatusChanged,
}) => {
  const [status, setStatus] = useState(data.status);

  const changeStatus = (status) => {
    db.collection(`companies/${company_id}/events`)
      .doc(data.id)
      .update({
        status: status,
      })
      .then(() => {
        toast.success("Event Status changed!");
        setEventStatusChanged(Math.random());
      })
      .catch((error) => {
        console.log("Error", error);
        toast.error("Failed to update status!");
      });
  };

  return (
    <div className="flex flex-col bg-white p-4 border rounded-md shadow-md">
      <div className="border shadow-lg">
        <img
          src={data.eventLogo}
          alt="Company Logo"
          className="w-full h-40 lg:h-56"
        ></img>
      </div>
      <div className="flex lg:flex-row lg:justify-between items-start py-5 flex-col-reverse">
        <div>
          <div className="flex gap-3 items-center">
            <p className="text-base font-semibold text-gray-700 mb-1 flex-nowrap">
              {data.eventTitle}
            </p>
            <div>
              <div
                className={`${
                  data.status
                    ? "bg-green-100 text-green-500"
                    : "bg-red-100 text-red-600"
                } px-2 rounded-xl`}
              >
                <p className="text-sm">{data.status ? "Open" : "Closed"}</p>
              </div>
            </div>
          </div>
          <p className="text-sm font-medium text-gray-400">
            {data.company_name}
          </p>
        </div>
      </div>

      <div className="flex items-center pt-2 gap-5 lg:gap-10">
        <div>
          <div className="flex items-center gap-2  text-gray-500 text-xs">
            <AiOutlinePlayCircle />
            STARTS AT
          </div>
          <p className="text-xs lg:text-base">
            {`${format(new Date(data.startsAt.seconds * 1000), "P")} - ${format(
              new Date(data.startsAt.seconds * 1000),
              "p"
            )}`}
          </p>
        </div>
        {/* <div>
          <div className="flex items-center gap-2 text-gray-500 text-xs">
            <FaRegMoneyBillAlt />
            STIPEND
          </div>
          <p className="text-xs lg:text-base">
            ₹ {data.minCTC}-{data.maxCTC} LPA
          </p>
        </div> */}
        <div>
          <div className="flex items-center gap-2 text-gray-500 text-xs">
            <AiOutlineHourglass />
            ENDS AT
          </div>
          <p className="text-xs lg:text-base">
            {`${format(new Date(data.endsAt.seconds * 1000), "P")} - ${format(
              new Date(data.endsAt.seconds * 1000),
              "p"
            )}`}
          </p>
        </div>
      </div>

      <div className="flex items-center justify-between my-2">
        <div className="flex gap-3">
          {!viewFromITHUB ? (
            <Link
              to={`/company/${company_id}/event/${data.id}/edit`}
              className=" py-2 bg-blue-100 px-4 rounded-md flex items-center gap-2 text-sm"
            >
              <MdEdit className="text-xl text-blue-600" />
            </Link>
          ) : null}
          <Link
            to={
              !viewFromITHUB
                ? `/company/${company_id}/event/${data.id}/details`
                : `/ITHub/events/${data.company_id}/${data.id}/details`
            }
            className=" py-2 bg-blue-100 px-4 rounded-md flex items-center gap-2 text-sm"
          >
            <BsFillEyeFill className="text-xl text-blue-600" />
            {viewFromITHUB ? (
              <p className="text-blue-600 text-md">View Details</p>
            ) : null}
          </Link>
        </div>
      </div>

      {!viewFromITHUB ? (
        <div className="flex gap-10 items-center mt-5">
          <p className="text-base font-medium">Event Status :</p>
          <div className="flex items-center gap-2">
            <p className="text-red-500 text-sm lg:text-lg font-medium">Close</p>
            <Switch
              type="checkbox"
              name="status"
              onChange={(checked) => {
                setStatus(!status);
                console.log("Checked", checked);
                changeStatus(checked);
              }}
              checked={status}
            />
            <p className="text-green-500 text-sm lg:text-lg font-medium">
              Open
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EventCard;
