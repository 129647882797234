import React, { useState } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { HiPhone } from "react-icons/hi";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const ViewApplicationModal = ({
  isModalOpen,
  setIsModalOpen,
  data,
  company_id,
  jobPostId,
  setStatusChanged,
}) => {
  const [statusChangeInProgress, setStatusChangeInProgress] = useState(false);
  //console.log("Modal Data", data);

  // const search = (nameKey, myArray) => {
  //   for (var i = 0; i < myArray.length; i++) {
  //     if (myArray[i].level === nameKey) {
  //       return myArray[i];
  //     }
  //   }
  // };

  const handleStatus = (role) => {
    setStatusChangeInProgress(role);
    db.collection(`/companies/${company_id}/jobs/${jobPostId}/applicants`)
      .doc(data.applicantId)
      .update({
        status: role,
      })
      .then(() => {
        setStatusChangeInProgress(false);
        setStatusChanged(data.applicantId);
        setIsModalOpen(false);
        toast.success(
          `Candidate ${
            role === "hired"
              ? "Hired"
              : role === "shortlist"
              ? "Shortlisted"
              : "Rejected"
          }`
        );
      })
      .catch((error) => {
        toast.error("Unable to perform operation!");
        console.log("Status Error", error);
      });
  };

  return (
    <div className="flex justify-center">
      <Modal
        isOpen={isModalOpen}
        //style={customStyles}
        onRequestClose={() => setIsModalOpen(false)}
        className="inset-x-auto rounded-md overflow-auto focus:outline-none absolute top-0 mt-20 lg:w-1/2 w-full px-2 flex flex-col"
        overlayClassName="transition-all ease-in-out duration-300 flex justify-center items-center bg-opacity-75 bg-black inset-0 fixed p-8 z-50"
      >
        <header className="rounded-t-md bg-black w-full py-5 px-12 text-white flex items-center justify-between">
          <div className="text-white">{data.name ? data.name : "N/A"}</div>
          <button onClick={() => setIsModalOpen(false)}>
            <MdClose className="w-6 h-6 text-white" />
          </button>
        </header>

        <div
          className="bg-white flex flex-col gap-3 p-2 overflow-y-auto"
          style={{ maxHeight: 500 }}
        >
          <div className="border p-5 flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-lg font-medium">
                  {data.name ? data.name : "N/A"}
                </p>
                <p className="text-xs font-medium text-gray-400">
                  {data.email ? data.email : "N/A"}
                </p>
              </div>
              <div>
                {data.resume ? (
                  <a
                    className="p-2 bg-blue-600 text-white rounded-lg hover:bg-blue-800 text-xs focus:outline-none"
                    href={data.resume}
                    download
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download Resume
                  </a>
                ) : (
                  <p>Resume not available</p>
                )}
              </div>
            </div>
            {data.skills ? (
              <div>
                <div>
                  <p className="text-md font-medium">Skills</p>
                  <div className="flex flex-wrap gap-4">
                    {data.skills.map((skill) => {
                      return (
                        <div
                          className="bg-gray-300 px-3 py-1 rounded-full gap-2 flex items-center justify-between"
                          key={skill}
                        >
                          <p className="font-medium flex flex-wrap">{skill}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
            <div>
              <p className="text-md font-medium">Contact Info</p>
              <div className="flex gap-2 items-center">
                <HiPhone />
                {data.phoneNumber ? data.phoneNumber : "N/A"}
              </div>
            </div>
          </div>

          <div className="border p-5 flex flex-col gap-4">
            <div className="flex justify-center">
              <p className="text-xl font-medium">Resume</p>
            </div>

            <div className="flex flex-col gap-10">
              <div className="flex flex-col  lg:gap-10 gap-5">
                <p className="text-md font-medium text-blue-600">EDUCATION</p>
                <div className="flex flex-col gap-4">
                  {data.education && data.education.length > 0
                    ? data.education.map((qualification) => {
                        return (
                          <div className="flex flex-col">
                            <p className="font-medium">
                              {qualification.level
                                ? qualification.level
                                : "N/A"}
                            </p>
                            <p className="text-sm font-light">
                              {qualification.schoolName
                                ? qualification.schoolName
                                : "N/A"}
                            </p>
                            <p className="text-sm font-light">
                              Field :{" "}
                              {qualification.field
                                ? qualification.field
                                : "N/A"}
                            </p>
                            <p className="text-sm font-light">
                              Learned :{" "}
                              {qualification.learned
                                ? qualification.learned
                                : "N/A"}
                            </p>
                          </div>
                        );
                      })
                    : "N/A"}
                </div>
              </div>
              <hr></hr>
              <div className="flex flex-col  lg:gap-10 gap-5">
                <p className="text-md font-medium text-blue-500">
                  WORK EXPERIENCE
                </p>
                <div className="flex flex-col gap-4">
                  {data.workExperience && data.workExperience.length > 0 ? (
                    <div>
                      {data.workExperience.map((item) => {
                        return (
                          <div className="flex flex-col">
                            <p className="font-medium">
                              {item.position ? item.position : "N/A"}
                            </p>
                            <p className="text-sm font-light">
                              {item.where ? item.where : "N/A"}
                            </p>
                            <p className="text-sm my-2">
                              {item.startDate
                                ? new Date(item.startDate.seconds * 1000)
                                    .toUTCString()
                                    .slice(8, 16)
                                : "N/A"}{" "}
                              -{" "}
                              {item.endDate
                                ? new Date(item.endDate.seconds * 1000)
                                    .toUTCString()
                                    .slice(8, 16)
                                : "N/A"}
                            </p>
                            <p className="text-sm">
                              {item.post ? item.post : "N/A"}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <p>N/A</p>
                    </div>
                  )}
                </div>
              </div>
              <hr></hr>
              <div className="flex flex-col  lg:gap-10 gap-5">
                <p className="text-md font-medium text-blue-500">
                  TASK CERTIFICATIONS
                </p>
                <div className="flex flex-col gap-4">
                  {data.taskCertificates && data.taskCertificates.length > 0 ? (
                    <div>
                      {data.taskCertificates.map((certificate) => {
                        return (
                          <div className="flex flex-col my-4">
                            <p className="font-medium">
                              {certificate.Course ? certificate.Course : "N/A"}
                            </p>
                            <p className="text-sm">
                              {certificate.startDate
                                ? new Date(certificate.startDate.seconds * 1000)
                                    .toUTCString()
                                    .slice(8, 16)
                                : "N/A"}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <p>N/A</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {data.status === "Applied" ? (
            <div className="border p-5 flex flex-col gap-4">
              <div className="flex lg:justify-end lg:gap-5 justify-evenly">
                <button
                  onClick={() => handleStatus("hired")}
                  disabled={statusChangeInProgress}
                  className="px-5 py-2 rounded-lg bg-green-500 text-white focus:outline-none font-medium"
                >
                  {statusChangeInProgress === "hired" ? (
                    <AiOutlineLoading3Quarters className=" animate-spin" />
                  ) : (
                    "Hire"
                  )}
                </button>
                <button
                  onClick={() => handleStatus("shortlist")}
                  disabled={statusChangeInProgress}
                  className="px-4 py-2 rounded-lg bg-blue-500 text-white focus:outline-none font-medium"
                >
                  {statusChangeInProgress === "shortlist" ? (
                    <AiOutlineLoading3Quarters className=" animate-spin" />
                  ) : (
                    "Shorlist"
                  )}
                </button>
                <button
                  onClick={() => handleStatus("reject")}
                  disabled={statusChangeInProgress}
                  className="px-4 py-2 rounded-lg bg-red-500 text-white focus:outline-none font-medium"
                >
                  {statusChangeInProgress === "reject" ? (
                    <AiOutlineLoading3Quarters className=" animate-spin" />
                  ) : (
                    "Reject"
                  )}
                </button>
              </div>
            </div>
          ) : data.status === "shortlist" ? (
            <div className="border p-5 flex flex-col gap-4">
              <div className="flex lg:justify-end lg:gap-5 justify-evenly">
                <button
                  onClick={() => handleStatus("hired")}
                  disabled={statusChangeInProgress}
                  className="px-5 py-2 rounded-lg bg-green-500 text-white focus:outline-none font-medium"
                >
                  {statusChangeInProgress === "hired" ? (
                    <AiOutlineLoading3Quarters className=" animate-spin" />
                  ) : (
                    "Hire"
                  )}
                </button>
                <button
                  onClick={() => handleStatus("reject")}
                  disabled={statusChangeInProgress}
                  className="px-4 py-2 rounded-lg bg-red-500 text-white focus:outline-none font-medium"
                >
                  {statusChangeInProgress === "reject" ? (
                    <AiOutlineLoading3Quarters className=" animate-spin" />
                  ) : (
                    "Reject"
                  )}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default ViewApplicationModal;
