import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { MdEdit } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { db } from "../firebase";

const AccordionElement = ({ item, viewFromITHUB, setNewsStatusChanged }) => {
  const [status, setStatus] = useState(item.status);
  let { companyId } = useParams();

  const changeStatus = (news, status) => {
    db.collection(`companies/${companyId}/news`)
      .doc(news.id)
      .update({
        status: status,
      })
      .then(() => {
        toast.success("News Status changed!");
        setNewsStatusChanged(Math.random());
      })
      .catch((error) => {
        console.log("Error", error);
        toast.error("Failed to update status!");
      });
  };

  return (
    <AccordionItem uuid={item.id}>
      <AccordionItemHeading>
        <AccordionItemButton>
          <span className="text-lg font-semibold">{item.newsTitle}</span>
        </AccordionItemButton>
      </AccordionItemHeading>

      <AccordionItemPanel>
        <p>{item.newsDescription}</p>
      </AccordionItemPanel>
      <div>
        {!viewFromITHUB ? (
          <div className="flex justify-between lg:gap-5 lg:justify-end m-5">
            <Link to={`/company/${companyId}/news/${item.id}/edit`}>
              <div className="px-3 py-2 flex items-center bg-yellow-400 gap-1 rounded-lg">
                <MdEdit className="text-xl" />
                <p className="text-sm">Edit News</p>
              </div>
            </Link>
            <div className="flex items-center gap-2">
              <p className="text-red-500 text-lg font-medium">Close</p>
              <Switch
                type="checkbox"
                name="status"
                onChange={(checked) => {
                  setStatus(!status);
                  console.log("Checked", checked);
                  changeStatus(item, checked);
                }}
                checked={status}
              />
              <p className="text-green-500 text-lg font-medium">Open</p>
            </div>
          </div>
        ) : null}
      </div>
    </AccordionItem>
  );
};

const NewsAccordion = ({ data, viewFromITHUB, setNewsStatusChanged }) => {
  return (
    <div>
      <Accordion preExpanded={[data[0].id]}>
        {data.map((item) => {
          return (
            <AccordionElement
              item={item}
              setNewsStatusChanged={setNewsStatusChanged}
              viewFromITHUB={viewFromITHUB}
            />
          );
        })}
      </Accordion>
    </div>
  );
};

export default NewsAccordion;
