import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Loading = () => {
  return (
    <div className="flex justify-center gap-2 items-center m-10">
      <AiOutlineLoading3Quarters className=" animate-spin" />
      <p className="text-lg text-gray-700">Loading...</p>
    </div>
  );
};

export default Loading;
