import React, { useContext, useState, useEffect } from "react";
import { isAfter } from "date-fns";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import { AiTwotoneDelete } from "react-icons/ai";
import * as Yup from "yup";
import Header from "../../../components/Header";
import { db, FirebaseContext } from "../../../firebase";
import { toast } from "react-toastify";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Loading from "../../../components/Loading";
import RequiredAsterisk from "../../../components/RequiredAsterisk";

const schema = Yup.lazy((values) => {
  return Yup.object().shape({
    title: Yup.string().nullable().required("Required!"),
    startsAt: Yup.date().nullable().required("Required!"),
    endsAt: Yup.date()
      .nullable()
      .required("Required!")
      .test(
        "greaterThanJobStartAt",
        "Date should be greater than start date",
        (value) => {
          if (value && values.startsAt) return isAfter(value, values.startsAt);
          return true;
        }
      ),
    applicationStartsAt: Yup.date().nullable().required("Required!"),
    applicationEndsAt: Yup.date()
      .nullable()
      .required("Required!")
      .test(
        "greaterThanJobStartAt",
        "Date should be greater than start date",
        (value) => {
          if (value && values.applicationStartsAt)
            return isAfter(value, values.applicationStartsAt);
          return true;
        }
      ),
    courseDescription: Yup.string().nullable().required("Required!"),
  });
});

const CreateEditCourse = () => {
  const { currentUser } = useContext(FirebaseContext);
  let { courseId, companyId } = useParams();
  const [courseDetails, setcourseDetails] = useState(null);
  const [courseDetailsError, setcourseDetailsError] = useState(false);
  const [addingNewCourse, setaddingNewCourse] = useState(false);
  const [courseAdded, setcourseAdded] = useState(false);

  const fetchCourseDetails = async () => {
    let data = await db
      .collection(`courses`)
      .doc(courseId)
      .get()
      .catch(() => {
        toast.error("Error while fetching Job Post Info!");
      });

    if (data && data.data()) {
      setcourseDetails(data.data());
      // let currentSkills = data.data().skills;
      // setSkills(
      //   currentSkills
      //     ? currentSkills.reduce((prev, cur) => ({ ...prev, [cur]: true }), {})
      //     : {}
      // );
    } else {
      toast.error("Job Post Data not available");
      setcourseDetailsError(true);
    }
  };

  useEffect(() => {
    if (courseId) {
      fetchCourseDetails();
    }
  }, []);

  if (courseDetailsError) {
    return (
      <div className="flex justify-center m-10">
        <p>Failed to load job post details...</p>
      </div>
    );
  }
  return (
    <div>
      <Header view="course" type="company" />

      {!courseId || courseDetails ? (
        <div className="lg:px-28 lg:pt-10">
          <Formik
            initialValues={
              courseDetails
                ? {
                    status: courseDetails.status,
                    title: courseDetails.title,
                    courseDescription: courseDetails.courseDescription,
                    startsAt: new Date(courseDetails.startsAt.seconds * 1000),
                    endsAt: new Date(courseDetails.endsAt.seconds * 1000),
                    difficulty: courseDetails.difficulty,
                    preRequisites: courseDetails.preRequisites,
                    applicationStartsAt: courseDetails.applicationStartsAt
                      ? new Date(
                          courseDetails.applicationStartsAt.seconds * 1000
                        )
                      : null,
                    applicationEndsAt: courseDetails.applicationEndsAt
                      ? new Date(courseDetails.applicationEndsAt.seconds * 1000)
                      : null,
                  }
                : {
                    status: false,
                    title: null,
                    courseDescription: null,
                    startsAt: null,
                    endsAt: null,
                    difficulty: "Beginner",
                    preRequisites: null,
                    applicationStartsAt: null,
                    applicationEndsAt: null,
                  }
            }
            validationSchema={schema}
            onSubmit={(values) => {
              console.log("Values", values);
              let finalData = {
                ...values,
                companyId: companyId,
              };
              if (!courseId) {
                setaddingNewCourse(true);
                db.collection(`courses`)
                  .add(finalData)
                  .then(() => {
                    toast.success("New course created successfully!");
                    setaddingNewCourse(false);
                    setcourseAdded(true);
                  })
                  .catch((error) => {
                    setaddingNewCourse(false);
                    console.log(error);
                    toast.error("Error occured while creating new course!");
                  });
              } else {
                setaddingNewCourse(true);
                db.collection(`courses`)
                  .doc(courseId)
                  .update(finalData)
                  .then(() => {
                    toast.success("Course edited successfully!");
                    setaddingNewCourse(false);
                  })
                  .catch((error) => {
                    console.log(error);

                    toast.error("Error occured while editing course!");
                  });
              }
            }}
          >
            {({ values, errors, setFieldValue }) => {
              return (
                <Form className="lg:w-10/12 mx-auto px-2 py-5 lg:px-0 lg:py-0">
                  <div className="flex justify-end items-center gap-4">
                    <p className="font-medium text-lg">Course Status</p>
                    <Switch
                      type="checkbox"
                      name="status"
                      onChange={(checked) => {
                        setFieldValue("status", checked ? true : false);
                      }}
                      checked={values.status}
                    />
                  </div>
                  <div className="flex flex-col gap-10">
                    <div>
                      <p className="text-xl font-medium">Course details</p>
                      <div className="p-5 mt-2 flex flex-col gap-4 rounded-lg border">
                        <div>
                          <div className="flex items-center gap-1">
                            <p>Course title</p>
                            <RequiredAsterisk />
                          </div>
                          <Field
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="e.g. Full Stack Web Development"
                            name="title"
                          />
                          <ErrorMessage
                            name="title"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>

                        <div>
                          <div className="flex items-center gap-1">
                            <p>About the course</p>
                            <RequiredAsterisk />
                          </div>

                          <Field
                            as="textarea"
                            rows={6}
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="Write a brief description about the course!.."
                            name="courseDescription"
                          />
                          <ErrorMessage
                            name="courseDescription"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>
                        <div>
                          <div className="flex items-center gap-1">
                            <p>Difficulty</p>
                          </div>
                          <div className="grid grid-cols-2 lg:grid-cols-4">
                            <div className="flex items-center gap-2">
                              <Field
                                type="radio"
                                name="difficulty"
                                value="Beginner"
                              />{" "}
                              <p>Beginner</p>
                            </div>
                            <div className="flex items-center gap-2">
                              <Field
                                type="radio"
                                name="difficulty"
                                value="Intermediate"
                              />
                              <p>Intermediate</p>
                            </div>
                            <div className="flex items-center gap-2">
                              <Field
                                type="radio"
                                name="difficulty"
                                value="Advanced"
                              />
                              Advanced
                            </div>
                            <div className="flex items-center gap-2">
                              <Field
                                type="radio"
                                name="difficulty"
                                value="Expert"
                              />
                              Expert
                            </div>
                          </div>
                        </div>
                        <div>
                          <p>Pre-Requisites (Optional)</p>
                          <Field
                            as="textarea"
                            rows={6}
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="Does the participant need to know anything beforehand?"
                            name="preRequisites"
                          />
                          <ErrorMessage
                            name="preRequisites"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between lg:gap-10">
                          <div className="w-full">
                            <div className="flex items-center gap-1">
                              <p>Course Start Date</p>
                              <RequiredAsterisk />
                            </div>

                            <Field
                              name="startsAt"
                              className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                              component={(props) => (
                                <CustomDatePicker
                                  {...props}
                                  format="do MMMM, yyyy hh:mm a"
                                  showTimeSelect={true}
                                  padded={true}
                                  placeholder="egregregr"
                                />
                              )}
                            />
                            <ErrorMessage
                              name="startsAt"
                              render={(msg) => (
                                <div className="text-red-600 text-sm">
                                  {msg}
                                </div>
                              )}
                            />
                          </div>

                          <div className="w-full">
                            <div className="flex items-center gap-1">
                              <p>Course End Date</p>

                              <RequiredAsterisk />
                            </div>
                            <Field
                              name="endsAt"
                              className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                              component={(props) => (
                                <CustomDatePicker
                                  {...props}
                                  format="do MMMM, yyyy hh:mm a"
                                  showTimeSelect={true}
                                  padded={true}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="endsAt"
                              render={(msg) => (
                                <div className="text-red-600 text-sm">
                                  {msg}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between lg:gap-10">
                          <div className="w-full">
                            <div className="flex items-center gap-1">
                              <p>Application Open Date</p>

                              <RequiredAsterisk />
                            </div>
                            <Field
                              name="applicationStartsAt"
                              className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                              component={(props) => (
                                <CustomDatePicker
                                  {...props}
                                  format="do MMMM, yyyy hh:mm a"
                                  showTimeSelect={true}
                                  padded={true}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="applicationStartsAt"
                              render={(msg) => (
                                <div className="text-red-600 text-sm">
                                  {msg}
                                </div>
                              )}
                            />
                          </div>

                          <div className="w-full">
                            <div className="flex items-center gap-1">
                              <p>Application Close Date</p>

                              <RequiredAsterisk />
                            </div>
                            <Field
                              name="applicationEndsAt"
                              className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                              component={(props) => (
                                <CustomDatePicker
                                  {...props}
                                  format="do MMMM, yyyy hh:mm a"
                                  showTimeSelect={true}
                                  padded={true}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="applicationEndsAt"
                              render={(msg) => (
                                <div className="text-red-600 text-sm">
                                  {msg}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-end items-center mb-5">
                      <div className="mr-10">
                        {Object.keys(errors).length > 0 ? (
                          <p className="text-red-500">
                            Please fill all the required details
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>

                      {!courseId ? (
                        !courseAdded ? (
                          <button
                            type="submit"
                            disabled={addingNewCourse}
                            className="px-6 py-2 bg-blue-600 rounded-lg text-white focus:outline-none"
                          >
                            {addingNewCourse ? (
                              <div className="flex gap-2 items-center justify-center">
                                <AiOutlineLoading3Quarters className=" animate-spin" />
                                <p>Submitting...</p>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        ) : (
                          <div className="px-6 py-2 bg-green-300 rounded-lg text-white focus:outline-none">
                            <p>Course Added</p>
                          </div>
                        )
                      ) : (
                        <button
                          type="submit"
                          disabled={addingNewCourse}
                          className="px-6 py-2 bg-blue-600 rounded-lg text-white focus:outline-none"
                        >
                          {addingNewCourse ? (
                            <div className="flex gap-2 items-center justify-center">
                              <AiOutlineLoading3Quarters className=" animate-spin" />
                              <p>Submitting...</p>
                            </div>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default CreateEditCourse;
