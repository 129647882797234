import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useParams, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import { db } from "../../../firebase";
import CandidateDetails from "./CandidateDetails";

const AllApplicantsEnrollments = () => {
  const [applicantsEnrollments, setApplicantsEnrollments] = useState(null);
  let { jobPostId, companyId, courseId } = useParams();
  const [statusChanged, setStatusChanged] = useState(null);
  const [
    searchedApplicantEnrollment,
    setSearchedApplicantEnrollment,
  ] = useState("");

  const { params } = useRouteMatch(
    jobPostId
      ? "/company/:companyId/job/:jobTitle/:jobPostId/applicants/:tab"
      : "/company/:companyId/course/:courseId/:courseTitle/enrollments/:tab"
  );

  const map = {
    all: "Applied",
    shortlist: "shortlist",
    hired: "hired",
    rejected: "reject",
    approve: "approve",
    reject: "reject",
  };

  const status = map[params.tab];
  const fetchApplicantsCount = async () => {
    let data = await db
      .collection(`companies/${companyId}/jobs/${jobPostId}/applicants`)
      .where("status", "==", status)
      .get()
      .catch((error) => {
        toast.error("Failed to fetch applicants count!");
        console.log("Applicants Error", error);
      });

    if (data && data.docs.length > 0) {
      let arr = data.docs.map((item) => {
        return { ...item.data(), applicantId: item.id };
      });

      //console.log("Applicant Data", arr);
      setApplicantsEnrollments(arr);
    } else {
      setApplicantsEnrollments([]);
    }
  };

  const fetchEnrollmentsCount = async () => {
    let data = await db
      .collection(`courses/${courseId}/enrollments`)
      .where("status", "==", status)
      .get()
      .catch((error) => {
        toast.error("Failed to fetch enrollments count!");
        console.log("Enrollments Error", error);
      });

    if (data && data.docs.length > 0) {
      let arr = data.docs.map((item) => {
        return { ...item.data(), applicantId: item.id };
      });

      //console.log("Applicant Data", arr);
      setApplicantsEnrollments(arr);
    } else {
      setApplicantsEnrollments([]);
    }
  };
  useEffect(() => {
    if (jobPostId) {
      fetchApplicantsCount();
    } else if (courseId) {
      fetchEnrollmentsCount();
    }
  }, [statusChanged]);

  if (applicantsEnrollments) {
    const filteredApplicants = applicantsEnrollments.filter(
      (applicantEnrollment) =>
        Object.values(applicantEnrollment).some(
          (item) =>
            typeof item === "string" &&
            item.includes(searchedApplicantEnrollment)
        )
    );
    //console.log("Filtered Applicants", filteredApplicants);
    return (
      <div>
        <div className="lg:mx-20 px-1 lg:px-0 flex flex-col gap-5">
          <div className="flex items-center">
            <BiSearch className="text-xl absolute ml-1 text-gray-500" />
            <input
              type="text"
              value={searchedApplicantEnrollment}
              onChange={(e) => {
                setSearchedApplicantEnrollment(e.target.value);
              }}
              className="w-full px-6 py-2 bg-gray-100 rounded border-2"
              placeholder="Search for applicant..."
            />
          </div>
          <div>
            {filteredApplicants.length > 0 ? (
              filteredApplicants.map((item) => {
                return (
                  <div className="mb-5">
                    <CandidateDetails
                      data={item}
                      status={status}
                      company_id={companyId}
                      jobPostId={jobPostId}
                      setStatusChanged={setStatusChanged}
                      courseId={courseId}
                    />
                  </div>
                );
              })
            ) : (
              <div className="flex justify-center m-10">
                <p className="text-lg text-red-500">No applicants found!...</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return <Loading />;
};

export default AllApplicantsEnrollments;
