import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import EventCard from "../../components/EventsCard";
import Loading from "../../components/Loading";
import { db } from "../../firebase";
import ITHubNews from "./ITHubNews";

const ITHubEventsNews = () => {
  const [events, setEvents] = useState(null);
  const [eventsFetchError, setEventsFetchError] = useState(false);

  const fetchAllEvents = async () => {
    let data = await db
      .collectionGroup("events")
      .where("status", "==", true)
      .get()
      .catch((error) => {
        toast.error("Failed to fetch events");
        setEventsFetchError(true);
        console.log(error);
      });

    if (data && data.docs && data.docs.length > 0) {
      console.log(data.docs[0].data());
      setEvents(
        data.docs.map((event) => {
          return {
            ...event.data(),
            id: event.id,
          };
        })
      );
    } else {
      setEvents([]);
    }
  };

  useEffect(() => {
    fetchAllEvents();
  }, []);

  // if (eventsFetchError) {
  //   return (
  //     <div className="flex justify-center m-10">
  //       <p className="text-red-500 text-xl">Failed to fetch events...</p>
  //     </div>
  //   );
  // }

  if (!events) {
    return <Loading />;
  }

  return (
    <div className="lg:p-10 p-3 flex flex-col gap-10">
      <div className="flex flex-col gap-5">
        <p className="text-lg font-semibold">Events</p>
        {events.length === 0 ? (
          <div className="flex justify-center m-10">
            <p className="text-blue-500 text-xl">
              No upcoming public events available...
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            {events.map((event) => {
              return <EventCard data={event} viewFromITHUB={true} />;
            })}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-5">
        <p className="text-lg font-semibold">News</p>
        <ITHubNews />
      </div>
    </div>
  );
};

export default ITHubEventsNews;
