import firebase from "firebase";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { BsFillGearFill } from "react-icons/bs";
import { FaImages, FaUsers } from "react-icons/fa";
import { HiOutlineDownload } from "react-icons/hi";
import { MdClose, MdEdit } from "react-icons/md";
import CsvDownload from "react-json-to-csv";
import Modal from "react-modal";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { db, FirebaseContext } from "../firebase";
import PlaceHolder from "../PlaceHolder.png";
import { GiFilmSpool } from "react-icons/gi";
import { Base64 } from "js-base64";

const BackButton = ({ type, viewFromITHUB }) => {
  let { companyId } = useParams();
  return (
    <div>
      <Link
        className="bg-gray-100 p-1 w-10 flex justify-center rounded-full text-2xl"
        to={
          viewFromITHUB
            ? "/ITHub/eventsNews"
            : `/${
                type !== "admin"
                  ? `company/${companyId}/dashboard`
                  : "admin/dashboard"
              }`
        }
      >
        <BiArrowBack className="text-2xl" />
      </Link>
    </div>
  );
};

const ResetPasswordModal = ({ isModalOpen, setIsModalOpen }) => {
  const { currentUser } = useContext(FirebaseContext);
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const [loadingResetLink, setLoadingResetLink] = useState(false);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(null)}
      className="inset-x-auto rounded-md overflow-auto focus:outline-none absolute my-auto lg:w-1/2 w-full px-2 flex flex-col"
      overlayClassName="transition-all ease-in-out duration-300 flex justify-center items-center bg-opacity-75 bg-black inset-0 fixed p-8 z-50"
    >
      <header className="rounded-t-md bg-black w-full py-5 px-12 text-white flex items-center justify-between">
        <div className="text-white">Reset Password</div>
        <button onClick={() => setIsModalOpen(null)}>
          <MdClose className="w-6 h-6 text-white" />
        </button>
      </header>

      <div className="bg-white p-5">
        <p className="text-lg font-medium my-2">Reset/Change Password</p>
        <p className="text-sm text-gray-500 my-2">
          Click on the link to get a reset link on your email
        </p>
        <button
          className={`${
            resetLinkSent ? "bg-green-500 cursor-default" : "bg-blue-500"
          } my-2 text-white px-3 py-2 rounded-lg focus:outline-none`}
          disabled={resetLinkSent}
          onClick={() => {
            setLoadingResetLink(true);
            firebase
              .auth()
              .sendPasswordResetEmail(currentUser.user.email)
              .then(() => {
                setLoadingResetLink(false);
                setResetLinkSent(true);
              });
          }}
        >
          {!resetLinkSent ? (
            <div className="flex gap-2 items-center">
              {loadingResetLink ? (
                <AiOutlineLoading3Quarters className=" animate-spin" />
              ) : null}
              <p>Get Reset Link</p>
            </div>
          ) : (
            "Link sent to your email"
          )}
        </button>
      </div>
    </Modal>
  );
};

const ApplicantsEnrollmentsCount = ({ setApplicantsData, type }) => {
  let { jobPostId, courseId, companyId } = useParams();
  const [count, setCount] = useState(null);

  let statusDictionary = {
    Applied: "Applied",
    none: "Applied",
    shortlist: "Shortlisted",
    hired: "Hired",
    reject: "Rejected",
    approve: "Approved",
  };

  const fetchApplicantsCount = async () => {
    let data =
      type !== "task"
        ? await db
            .collection(`companies/${companyId}/jobs/${jobPostId}/applicants`)
            .get()
            .catch(() => {
              toast.error("Failed to fetch applicants count in header!");
            })
        : await db
            .collection(`courses/${courseId}/enrollments`)
            .get()
            .catch(() => {
              toast.error("Failed to fetch applicants count in header!");
            });

    console.log("ENro Data", data.docs, courseId);

    if (data && data.docs) {
      if (data.docs.length > 0) {
        setApplicantsData(
          data.docs.map((applicant) => {
            return {
              Name: applicant.data().name,
              Email: applicant.data().email ? applicant.data().email : "N/A",
              PhoneNumber: applicant.data().phoneNumber
                ? applicant.data().phoneNumber
                : "N/A",
              Status: statusDictionary[applicant.data().status],
              Resume: applicant.data().resume ? applicant.data().resume : "N/A",
            };
          })
        );
        setCount(data.docs.length);
      } else {
        setCount("0");
      }
    } else {
      setCount(0);
    }
  };

  useEffect(() => {
    fetchApplicantsCount();
  }, []);

  return (
    <div className="flex items-center">
      (
      {count ? (
        count
      ) : (
        <AiOutlineLoading3Quarters className=" animate-spin text-sm font-semibold" />
      )}
      )
    </div>
  );
};

const Header = ({ view, roleName, type, viewFromITHUB, profilePic }) => {
  const { currentUser } = useContext(FirebaseContext);
  const [applicantsData, setApplicantsData] = useState(null);
  let { jobPostId, jobTitle, courseId, courseTitle, companyId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  switch (view) {
    case "dashboard":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container flex flex-col gap-5 lg:flex-row lg:justify-between lg:items-center px-4 py-8 lg:px-20">
            <div className="mr-10">
              {type === "admin" ? (
                <div className="flex items-center gap-2">
                  <p className="text-xl">Welcome</p>
                </div>
              ) : null}
              {type === "company" ? (
                <div className="flex items-center gap-2">
                  {currentUser.user.type === "admin" ? (
                    <BackButton type="admin" />
                  ) : null}
                  {profilePic ? (
                    <img
                      src={profilePic}
                      alt="Company Pic"
                      width="100"
                      height="100"
                      className="rounded-lg"
                    />
                  ) : (
                    <img
                      src={PlaceHolder}
                      alt="Company Pic"
                      width="100"
                      height="100"
                      className="rounded-lg"
                    />
                  )}
                  <p className="text-base lg:text-xl font-semibold">
                    {roleName}
                  </p>
                </div>
              ) : type === "admin" ? (
                <p className="text-base lg:text-xl font-semibold">
                  {currentUser.user.name}
                </p>
              ) : null}
            </div>
            <div className="flex gap-3 justify-end items-center">
              {type === "admin" ? (
                <div className="flex gap-5">
                  <Link
                    to={`/admin/talentPool`}
                    className="flex gap-2 items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-lg focus:outline-none hover:bg-blue-800"
                  >
                    <GiFilmSpool
                      className="text-base text-white"
                      style={{ color: "white" }}
                    />
                    <p className="hidden lg:block text-sm">Talent Pool</p>
                  </Link>
                  <Link
                    to={`/admin/gallery`}
                    className="flex gap-2 items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-lg focus:outline-none hover:bg-blue-800"
                  >
                    <FaImages
                      className="text-base text-white"
                      style={{ color: "white" }}
                    />
                    <p className="hidden lg:block text-sm">Manage Gallery</p>
                  </Link>
                </div>
              ) : null}
              {type === "company" ? (
                <div className="flex gap-5">
                  <Link
                    to={`/company/${
                      currentUser.company
                        ? currentUser.company.company_id
                        : companyId
                    }/edit`}
                    className="flex gap-2 items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-lg focus:outline-none hover:bg-blue-800"
                  >
                    <MdEdit className="text-base" />
                    <p className="hidden lg:block text-sm">Edit Profile</p>
                  </Link>
                  <Link
                    to={`/company/gallery/${companyId}`}
                    className="flex gap-2 items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-lg focus:outline-none hover:bg-blue-800"
                  >
                    <FaImages
                      className="text-base text-white"
                      style={{ color: "white" }}
                    />
                    <p className="hidden lg:block text-sm">Gallery</p>
                  </Link>
                </div>
              ) : null}
              <div>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="bg-blue-500 p-2 text-white rounded-lg focus:outline-none"
                >
                  <BsFillGearFill />
                </button>

                {isModalOpen ? (
                  <ResetPasswordModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </header>
      );
    case "applicants":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container">
            <div className="container flex justify-between items-center px-2 py-4 lg:py-8 lg:px-20">
              <div className="flex items-center gap-3">
                <div>
                  <BackButton type={type} />
                </div>
                <p>
                  <div className="text-lg lg:text-xl mt-2 lg:mt-0 font-semibold flex items-center gap-2">
                    <FaUsers />
                    <span className="flex gap-1">
                      {type !== "task" ? "Applicants" : "Enrollments"}
                      {
                        <ApplicantsEnrollmentsCount
                          setApplicantsData={setApplicantsData}
                          type={type}
                        />
                      }
                    </span>
                  </div>
                </p>
              </div>
              <div className="flex justify-center">
                {/* <div className="lg:flex md:flex justify-center hidden">
                  <button className="px-2 bg-gray-400 focus:outline-none">
                    <BsSearch />
                  </button>
                  <input
                    className="px-3 w-2/3 focus:outline-none"
                    placeholder="Search"
                  />
                </div> */}

                <div className=" hidden lg:flex">
                  {applicantsData ? (
                    <CsvDownload
                      data={applicantsData}
                      filename={`${
                        type !== "task" ? Base64.decode(jobTitle) : courseTitle
                      }.csv`}
                      style={{
                        background: "#0e8feb",
                        borderRadius: "6px",
                        display: "inline-block",
                        cursor: "pointer",
                        color: "#ffffff",
                        fontSize: "15px",
                        fontWeight: "bold",
                        padding: "4px 20px",
                        textDecoration: "none",
                        textShadow: "0px 1px 0px #9b14b3",
                      }}
                    >
                      <div className="flex gap-2 justify-center items-center">
                        <HiOutlineDownload className=" font-semibold text-xl" />
                        <p>Excel</p>
                      </div>
                    </CsvDownload>
                  ) : null}
                </div>

                {/* <button className="hidden lg:flex items-center justify-center px-5 font-medium py-2 bg-green-400 text-white gap-2 rounded-lg focus:outline-none">
                  <HiOutlineDownload />
                  Excel
                </button> */}
              </div>
            </div>
            <div className="lg:hidden flex container px-2 mb-5 lg:mb-0">
              {/* <div className="flex container md:hidden">
                <button className="p-3 bg-gray-400 focus:outline-none">
                  <BsSearch />
                </button>
                <input
                  className="p-3 w-2/3 h-10 focus:outline-none"
                  placeholder="Search"
                />
              </div> */}

              {applicantsData ? (
                <CsvDownload
                  data={applicantsData}
                  filename={`${jobTitle}.csv`}
                  style={{
                    boxShadow: "inset 0px 1px 0px 0px #e184f3",
                    background:
                      "linear-gradient(to bottom, red 5%, tomato 100%)",
                    borderRadius: "6px",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "#ffffff",
                    fontSize: "13px",
                    fontWeight: "bold",
                    padding: "4px 10px",
                    textDecoration: "none",
                    textShadow: "0px 1px 0px #9b14b3",
                  }}
                >
                  <div className="flex gap-2 justify-center items-center">
                    <HiOutlineDownload className=" font-semibold text-lg" />
                    <p>Excel</p>
                  </div>
                </CsvDownload>
              ) : null}

              {/* <button className="flex items-center justify-center px-5 py-2 bg-green-400 text-white gap-2 rounded-lg font-medium focus:outline-none">
                <HiOutlineDownload />
                Excel
              </button> */}
            </div>
          </div>
        </header>
      );
    case "jobPost":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container flex gap-3 items-center  px-4 py-8 lg:px-20">
            <BackButton type={type} viewFromITHUB={viewFromITHUB} />

            <div className="mr-10">
              {type !== "task" ? (
                <p className="text-base lg:text-xl font-semibold">
                  {jobPostId ? "Edit job post" : "Add new job post"}
                </p>
              ) : (
                <p className="text-base lg:text-xl font-semibold">
                  {courseId ? "Edit course" : "Add new course"}
                </p>
              )}
            </div>
          </div>
        </header>
      );
    case "jobDetails":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container flex items-center gap-3 px-4 py-8 lg:px-20">
            <BackButton type={type} />

            <div className="mr-10">
              <p className="text-base lg:text-xl font-semibold">{roleName}</p>
            </div>
          </div>
        </header>
      );
    case "editCompany":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container flex items-center gap-3 px-4 py-8 lg:px-20">
            <div>
              <Link
                className="bg-gray-100 p-1 w-10 flex justify-center rounded-full text-2xl"
                to={
                  companyId ? `/company/${companyId}/dashboard` : "/dashboard"
                }
              >
                <BiArrowBack className="text-2xl" />
              </Link>
            </div>

            <div className="mr-10">
              <p className="text-base lg:text-xl font-semibold">
                {companyId ? "Edit Company Details" : "Add Company Details"}
              </p>
            </div>
          </div>
        </header>
      );
    case "gallery":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container flex items-center gap-3 px-4 py-8 lg:px-20">
            <BackButton type={type} />

            <div className="mr-10">
              <p className="text-base lg:text-xl font-semibold">Gallery</p>
            </div>
          </div>
        </header>
      );
    case "eventOrNews":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container flex items-center gap-3 px-4 py-8 lg:px-20">
            <BackButton type={type} viewFromITHUB={viewFromITHUB} />

            <div className="mr-10">
              <p className="text-base lg:text-xl font-semibold">
                Add a new event/news
              </p>
            </div>
          </div>
        </header>
      );
    case "eventDetails":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container flex items-center gap-3 px-4 py-8 lg:px-20">
            <BackButton type={type} viewFromITHUB={viewFromITHUB} />

            <div className="mr-10">
              <p className="text-base lg:text-xl font-semibold">{roleName}</p>
            </div>
          </div>
        </header>
      );
    case "editNews":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container flex items-center gap-3 px-4 py-8 lg:px-20">
            <BackButton type={type} viewFromITHUB={viewFromITHUB} />

            <div className="mr-10">
              <p className="text-base lg:text-xl font-semibold">Edit news</p>
            </div>
          </div>
        </header>
      );
    case "editEvent":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container flex items-center gap-3 px-4 py-8 lg:px-20">
            <BackButton type={type} />

            <div className="mr-10">
              <p className="text-base lg:text-xl font-semibold">Edit event</p>
            </div>
          </div>
        </header>
      );
    case "managers":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container flex items-center gap-3 px-4 py-8 lg:px-20">
            <BackButton type={type} />

            <div className="mr-10">
              <p className="text-base lg:text-xl font-semibold">
                Add/View Managers
              </p>
            </div>
          </div>
        </header>
      );
    case "course":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container flex items-center gap-3 px-4 py-8 lg:px-20">
            <BackButton type={type} />

            <div className="mr-10">
              <p className="text-base lg:text-xl font-semibold">
                {courseId ? "Edit course" : "Add new course"}
              </p>
            </div>
          </div>
        </header>
      );
    case "courseDetails":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container flex items-center gap-3 px-4 py-8 lg:px-20">
            <BackButton type={type} />

            <div className="mr-10">
              <p className="text-base lg:text-xl font-semibold">{roleName}</p>
            </div>
          </div>
        </header>
      );
    case "talentPool":
      return (
        <header className="w-full flex items-center justify-around bg-green-100 border-green-400 border-b-4 lg:border-b-4">
          <div className="container flex items-center gap-3 px-4 py-8 lg:px-20">
            <BackButton type={type} />

            <div className="mr-10">
              <p className="text-base lg:text-xl font-semibold">Talent Pool</p>
            </div>
          </div>
        </header>
      );
    default:
      return null;
  }
};

export default Header;
