import React, { useState, useEffect } from "react";
import Loading from "../../../../components/Loading";

const MaleFemale = ({ data }) => {
  const [maleCount, setMaleCount] = useState(null);
  const [femaleCount, setFemaleCount] = useState(null);

  useEffect(() => {
    let male = 0,
      female = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].gender === "Male") {
        male++;
      } else if (data[i].gender === "Female") {
        female++;
      }
    }

    setMaleCount(male);
    setFemaleCount(female);
  }, []);

  //console.log("count", maleCount, femaleCount);

  if (!maleCount || !femaleCount) {
    return <Loading />;
  }

  return (
    <div className="bg-blue-100 rounded-lg shadow">
      <div className="flex justify-center bg-gray-600 text-white py-2 text-lg">
        <p>Gender Ratio</p>
      </div>
      <div className="flex justify-between p-5">
        <div className="flex justify-center flex-col">
          <p className="text-lg font-medium text-gray-400">Male</p>
          <p className="text-2xl italic">{maleCount}</p>
        </div>
        <div className="flex justify-center flex-col">
          <p className="text-lg font-medium text-gray-400">Female</p>
          <p className="text-2xl italic">{femaleCount}</p>
        </div>
        <div className="flex justify-center flex-col">
          <p className="text-lg font-medium text-gray-400">Male : Female</p>
          <p className="text-2xl italic">
            {(maleCount / (femaleCount + maleCount)).toPrecision(1) * 10} :{" "}
            {(femaleCount / (femaleCount + maleCount)).toPrecision(1) * 10}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MaleFemale;
