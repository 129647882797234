import React, { useContext, useEffect, useState } from "react";
import { AiOutlineHourglass, AiOutlinePlayCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../../components/Header";
import Loading from "../../../../components/Loading";
import { db, FirebaseContext } from "../../../../firebase";

const EventDetails = ({ viewFromITHUB }) => {
  let { eventId, companyId } = useParams();
  const { currentUser } = useContext(FirebaseContext);

  const [eventDetails, seteventDetails] = useState(null);
  const [eventDetailsError, seteventDetailsError] = useState(false);

  const fetchEventDetails = async () => {
    let data = await db
      .collection(`companies/${companyId}/events`)
      .doc(eventId)
      .get()
      .catch((error) => {
        console.log(error);
        toast.error("Error while fetching Job Post Info!");
        seteventDetailsError(true);
      });

    if (data && data.data()) {
      seteventDetails(data.data());
    } else {
      toast.error("Job Post Details not available");
      seteventDetailsError(true);
    }
  };

  useEffect(() => {
    fetchEventDetails();
  }, []);

  if (eventDetailsError) {
    return (
      <div className="flex m-10 justify-center">
        <p>Failed to load job post details!...</p>
      </div>
    );
  }

  if (!eventDetails) {
    return <Loading />;
  }

  return (
    <div>
      <Header
        view="eventDetails"
        roleName={eventDetails.eventTitle}
        viewFromITHUB={viewFromITHUB}
      />

      <div className="lg:mx-28 lg:w-7/12 border px-5 my-5 rounded-xl">
        <div className="w-full border flex justify-center my-5">
          <img
            src={eventDetails.eventLogo ? eventDetails.eventLogo : null}
            alt="Event Cover Pic"
            className="lg:h-80 w-full"
          />
        </div>
        <div className="flex-col gap-4 px-2 mb-5 pb-5 border-b">
          <div className="lg:py-5">
            <p className="text-xl font-semibold">{eventDetails.eventTitle}</p>
            <p className="text-sm text-gray-500">{eventDetails.company_name}</p>
          </div>

          <div className="grid grid-cols-2 lg:flex lg:gap-20 w-full gap-5 my-5">
            <div>
              <div className="flex items-center gap-2  text-gray-500 text-sm">
                <AiOutlinePlayCircle />
                START DATE
              </div>
              <p className="text-sm">
                {`${new Date(
                  eventDetails.startsAt.seconds * 1000
                ).getDate()} / ${
                  new Date(eventDetails.startsAt.seconds * 1000).getMonth() + 1
                } / ${new Date(
                  eventDetails.startsAt.seconds * 1000
                ).getFullYear()}`}
              </p>
            </div>
            <div>
              <div className="flex items-center gap-2 text-gray-500 text-sm">
                <AiOutlineHourglass />
                APPLY BY
              </div>
              <p className="text-sm">
                {`${new Date(eventDetails.endsAt.seconds * 1000).getDate()} / ${
                  new Date(eventDetails.endsAt.seconds * 1000).getMonth() + 1
                } / ${new Date(
                  eventDetails.endsAt.seconds * 1000
                ).getFullYear()}`}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-3">
            <p className="text-xl">About {eventDetails.company_name}</p>
            <p className="text-sm">{eventDetails.aboutUs}</p>
          </div>
          <p className="text-xl">About the event</p>
          <div className="text-sm">{eventDetails.eventDescription}</div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
