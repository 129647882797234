import { format } from "date-fns";
import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
import ViewApplicationModal from "./ViewApplicationModal";

const CandidateDetails = ({
  data,
  status,
  company_id,
  jobPostId,
  courseId,
  setStatusChanged,
}) => {
  //console.log("Applicant Data", data);

  const [statusChangeInProgress, setStatusChangeInProgress] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleApplicantStatus = (role) => {
    setStatusChangeInProgress(role);
    db.collection(`/companies/${company_id}/jobs/${jobPostId}/applicants`)
      .doc(data.applicantId)
      .update({
        status: role,
      })
      .then(() => {
        setStatusChangeInProgress(false);
        setStatusChanged(data.applicantId);
        toast.success(
          `Candidate ${
            role === "hired"
              ? "Hired"
              : role === "shortlist"
              ? "Shortlisted"
              : "Rejected"
          }`
        );
      })
      .catch((error) => {
        toast.error("Unable to perform operation!");

        setStatusChangeInProgress(false);

        console.log("Status Error", error);
      });
  };

  const handleEnrollmentStatus = (role) => {
    setStatusChangeInProgress(role);
    db.collection(`/courses/${courseId}/enrollments`)
      .doc(data.applicantId)
      .update({
        status: role,
      })
      .then(() => {
        setStatusChangeInProgress(false);
        setStatusChanged(data.enrollmentId);
        toast.success(
          `Candidate ${role === "approve" ? "Approved" : "Rejected"}`
        );
      })
      .catch((error) => {
        toast.error("Unable to perform operation!");
        setStatusChangeInProgress(false);
        console.log("Status Error", error);
      });
  };

  const search = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].level === nameKey) {
        return myArray[i];
      }
    }
  };

  let btech = search("Bachelors/Honors", data.education);
  // console.log("Btech", btech);

  return (
    <div className="p-3 border rounded flex flex-col gap-4">
      <div className="flex lg:justify-between lg:flex-row flex-col gap-4">
        <div>
          <p className="text-xl font-medium">{data.name ? data.name : "N/A"}</p>
          <p className="text-sm font-medium text-gray-400">
            {data.email ? data.email : "N/A"}
          </p>
        </div>
        <div>
          <p className="text-md font-medium text-gray-400">
            Applied On :{" "}
            {data.date
              ? `${format(new Date(data.date.seconds * 1000), "P")}`
              : "N/A"}
          </p>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-3 lg:gap-10">
        <div>
          <p className="text-sm font-medium text-gray-400">COLLEGE</p>
          <p className="font-medium">
            {btech && btech.schoolName ? btech.schoolName : "N/A"}
          </p>
        </div>
        <div>
          <p className="text-sm font-medium text-gray-400">EDUCATION</p>
          <p className="font-medium">
            {btech && btech.level ? btech.level : "N/A"}
          </p>

          {/* {btech && btech.field ? (
            <p className="text-sm">{btech.field}</p>
          ) : null} */}
        </div>
      </div>

      <div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="text-blue-400 font-medium"
        >
          View full application
        </button>
      </div>

      {isModalOpen ? (
        <ViewApplicationModal
          data={data}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          company_id={company_id}
          jobPostId={jobPostId}
          setStatusChanged={setStatusChanged}
        />
      ) : null}

      {jobPostId ? (
        <div>
          {status === "Applied" ? (
            <div className="flex lg:justify-end lg:gap-5 justify-evenly">
              <button
                onClick={() => handleApplicantStatus("hired")}
                disabled={statusChangeInProgress}
                className="px-4 py-1 rounded-sm bg-green-500 text-white focus:outline-none font-medium"
              >
                {statusChangeInProgress === "hired" ? (
                  <AiOutlineLoading3Quarters className=" animate-spin" />
                ) : (
                  "Hire"
                )}
              </button>
              <button
                onClick={() => handleApplicantStatus("shortlist")}
                disabled={statusChangeInProgress}
                className="px-4 py-1 rounded-sm bg-blue-500 text-white focus:outline-none font-medium"
              >
                {statusChangeInProgress === "shortlist" ? (
                  <AiOutlineLoading3Quarters className=" animate-spin" />
                ) : (
                  "Shorlist"
                )}
              </button>
              <button
                onClick={() => handleApplicantStatus("reject")}
                disabled={statusChangeInProgress}
                className="px-4 py-1 rounded-sm bg-red-500 text-white focus:outline-none font-medium"
              >
                {statusChangeInProgress === "reject" ? (
                  <AiOutlineLoading3Quarters className=" animate-spin" />
                ) : (
                  "Reject"
                )}
              </button>
            </div>
          ) : status === "shortlist" ? (
            <div className="flex lg:justify-end lg:gap-5 justify-evenly">
              <button
                onClick={() => handleApplicantStatus("hired")}
                disabled={statusChangeInProgress}
                className="px-5 py-2 rounded-lg bg-green-500 text-white focus:outline-none font-medium"
              >
                {statusChangeInProgress === "hired" ? (
                  <AiOutlineLoading3Quarters className=" animate-spin" />
                ) : (
                  "Hire"
                )}
              </button>

              <button
                onClick={() => handleApplicantStatus("reject")}
                disabled={statusChangeInProgress}
                className="px-4 py-2 rounded-lg bg-red-500 text-white focus:outline-none font-medium"
              >
                {statusChangeInProgress === "reject" ? (
                  <AiOutlineLoading3Quarters className=" animate-spin" />
                ) : (
                  "Reject"
                )}
              </button>
            </div>
          ) : null}
        </div>
      ) : courseId ? (
        <div>
          {status === "Applied" ? (
            <div className="flex lg:justify-end lg:gap-5 justify-evenly">
              <button
                onClick={() => handleEnrollmentStatus("approve")}
                disabled={statusChangeInProgress}
                className="px-5 py-2 rounded-lg bg-green-500 text-white focus:outline-none font-medium"
              >
                {statusChangeInProgress === "approve" ? (
                  <AiOutlineLoading3Quarters className=" animate-spin" />
                ) : (
                  "Approve"
                )}
              </button>
              <button
                onClick={() => handleEnrollmentStatus("reject")}
                disabled={statusChangeInProgress}
                className="px-4 py-2 rounded-lg bg-red-500 text-white focus:outline-none font-medium"
              >
                {statusChangeInProgress === "reject" ? (
                  <AiOutlineLoading3Quarters className=" animate-spin" />
                ) : (
                  "Reject"
                )}
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CandidateDetails;
