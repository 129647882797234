import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EventCard from "../../../../components/EventsCard";
import Loading from "../../../../components/Loading";
import { db, FirebaseContext } from "../../../../firebase";

const EventsList = () => {
  const { currentUser } = useContext(FirebaseContext);

  const [events, setEvents] = useState(null);
  const [eventStatusChanged, setEventStatusChanged] = useState(null);
  let { companyId } = useParams();

  const fetchEvents = async () => {
    let data = await db
      .collection(`companies/${companyId}/events`)
      .get()
      .catch((error) => {
        console.log("Unable to fetch events!", error);
      });

    console.log("Data", data.docs);

    if (data && data.docs && data.docs.length > 0) {
      let arr = data.docs.map((event) => {
        return { ...event.data(), id: event.id };
      });
      //console.log("Arr", arr);
      setEvents(arr);
    } else {
      setEvents([]);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [eventStatusChanged]);

  return (
    <div>
      {events ? (
        events.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 pb-5 ">
            {events.map((event, index) => (
              <div key={event.id}>
                <EventCard
                  data={event}
                  viewFromITHUB={false}
                  company_id={companyId}
                  setEventStatusChanged={setEventStatusChanged}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center mx-10">
            <p>No events available!</p>
          </div>
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default EventsList;
