import React, { useState, useEffect, useContext } from "react";
import Header from "./Header";
import { BiImageAdd } from "react-icons/bi";
import { db, FirebaseContext, storageRef } from "../firebase";
import { toast } from "react-toastify";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { FaHourglassHalf } from "react-icons/fa";
import DeleteModal from "./DeleteModal";
import Loading from "./Loading";
import { useParams } from "react-router-dom";

const Gallery = ({ role }) => {
  const [images, setImages] = useState(null);
  const [imageIdToDelete, setImageIdToDelete] = useState(null);

  const [imageUploadInProgress, setImageUploadInProgress] = useState(false);
  let { companyId } = useParams();
  let { currentUser } = useContext(FirebaseContext);

  const fetchCompanyImages = async () => {
    let data = await db
      .collection(`companies/${companyId}/gallery`)
      .get()
      .catch((error) => {
        toast.error("Failed to fetch gallery!");
        console.log("Company Gallery Failure", error);
      });
    console.log("Company Gallery", data.docs);

    if (data && data.docs) {
      if (data.docs.length > 0) {
        setImages(
          data.docs.map((el) => {
            return {
              original: el.data().url,
              thumbnail: el.data().url,
              id: el.id,
            };
          })
        );
      } else {
        setImages([]);
      }
    } else {
      toast.error("No data found!");
    }
  };

  const fetchAdminImages = async () => {
    let data = await db
      .collection(`ithub/6s9XvaNVVFACkHjbz0Pi/gallery`)
      .get()
      .catch((error) => {
        toast.error("Failed to fetch gallery!");
        console.log("Admin Gallery Failure", error);
      });
    console.log("Admin Gallery", data.docs);
    if (data && data.docs) {
      if (data.docs.length > 0) {
        setImages(
          data.docs.map((el) => {
            return {
              original: el.data().url,
              thumbnail: el.data().url,
              id: el.id,
            };
          })
        );
      } else {
        setImages([]);
      }
    } else {
      toast.error("No data found!");
    }
  };

  useEffect(() => {
    if (role === "companyGallery") {
      fetchCompanyImages();
    } else if (role === "adminGallery") {
      fetchAdminImages();
    }
  }, [imageUploadInProgress, imageIdToDelete]);

  const uploadimage = async (image) => {
    var d = new Date();
    var n = d.getTime();
    const fileRef = storageRef
      .ref(
        role === "companyGallery"
          ? `companyImages/${companyId}`
          : "ItHubGallery"
      )
      .child(image.name + n);
    await fileRef.put(image).catch((error) => {
      toast.error("Failed to upload picture!...");
      console.log("Firebase image upload failure", error);
      return false;
    });
    let url = await fileRef.getDownloadURL();
    //console.log(url);
    return url ? url : false;
  };

  const uploadCompanyImages = async (companyImages) => {
    setImageUploadInProgress(true);
    let noOfImages = Object.keys(companyImages).length;
    let imagesURLStatus = [];

    for (let i = 0; i < noOfImages; i++) {
      const image = companyImages[i];
      let url = await uploadimage(image);
      if (url) {
        db.collection("companies")
          .doc(companyId)
          .collection("gallery")
          .add({
            url: url,
            name:
              currentUser.user.type === "admin"
                ? "IT HUB KHAMMAM"
                : currentUser.company.name,
          })
          .then(() => {
            imagesURLStatus.push(url);
          })
          .catch((error) => {
            toast.error("Failed to upload image!");
            console.log("Gallery single image upload failure", error);
          });
      } else {
        toast.error("Image URL not returned");
      }
    }
    //     console.log(
    //       "Stage 1 completed!",
    //       imagesURLStatus,
    //       imagesURLStatus.length,
    //       noOfImages
    //     );
    if (imagesURLStatus.length + 1 === noOfImages) {
      setImageUploadInProgress(false);
      toast.success("Images uploaded successfully!...");
    } else {
      toast.error("Checking error!");
      setImageUploadInProgress(false);
    }
  };

  const uploadAdminImages = async (adminImages) => {
    setImageUploadInProgress(true);
    let noOfImages = Object.keys(adminImages).length;
    let imagesURLStatus = [];

    for (let i = 0; i < noOfImages; i++) {
      const image = adminImages[i];
      let url = await uploadimage(image);
      if (url) {
        db.collection("ithub")
          .doc("6s9XvaNVVFACkHjbz0Pi")
          .collection("gallery")
          .add({
            url: url,
            name: "IT HUB KHAMMAM",
          })
          .then(() => {
            imagesURLStatus.push(url);
          })
          .catch((error) => {
            toast.error("Failed to upload image!");
            console.log("Gallery single image upload failure", error);
          });
      } else {
        toast.error("Image URL not returned");
      }
    }
    if (imagesURLStatus.length + 1 === noOfImages) {
      setImageUploadInProgress(false);
      toast.success("Images uploaded successfully!...");
    } else {
      toast.error("Checking error!");
      setImageUploadInProgress(false);
    }
  };

  const confirmDelete = (id) => {
    setImageIdToDelete(id);
  };
  return (
    <div>
      <Header
        view="gallery"
        type={role === "adminGallery" ? "admin" : "company"}
      />
      <div className="lg:px-20 lg:pt-10 lg:mx-auto mx-2 mt-10">
        <p className="font-medium text-lg">Add new Image</p>
        <div className="flex flex-col border p-5 w-full">
          <div className="flex justify-center">
            <input
              type="file"
              multiple
              onChange={(e) => {
                if (e.target && e.target.files) {
                  if (role === "companyGallery") {
                    uploadCompanyImages(e.target.files);
                  } else if (role === "adminGallery") {
                    uploadAdminImages(e.target.files);
                  }
                }
              }}
              accept="image/x-png,image/image/jpeg/png"
              id="actual-btn"
              hidden
            />

            <label htmlFor="actual-btn">
              <BiImageAdd className="text-9xl cursor-pointer hover:shadow-md bg-gray-200 hover:text-white hover:bg-black focus:outline-none rounded-full p-3" />
            </label>
          </div>

          {imageUploadInProgress ? (
            <div className="flex lg:justify-end justify-center mt-5">
              <div className="bg-gray-400 px-3 py-2 text-white flex rounded-lg text-xs lg:text-sm items-center gap-2">
                <FaHourglassHalf className="animate-spin" />
                <p>Uploading</p>
              </div>
            </div>
          ) : null}
        </div>

        <div className="flex justify-center my-10 border p-5">
          {!images ? (
            <Loading />
          ) : images.length === 0 ? (
            <div className="m-10 flex">
              <p className="text-lg text-red-500">No images found!...</p>
            </div>
          ) : (
            <div className="flex flex-col gap-10 w-full items-center">
              <div className="lg:w-1/2 w-full border border-black p-1">
                <ImageGallery items={images} showThumbnails={false} autoPlay />
              </div>
              <div className="grid lg:grid-cols-5 items-center justify-center gap-5 lg:border p-5 grid-cols-1">
                {images &&
                  images.map((image) => {
                    return (
                      <div className="flex flex-col gap-3">
                        <div className="cursor-pointer border border-black lg:w-1/2 rounded-xl hover:shadow-lg hover:border-red-500">
                          <img
                            onClick={() =>
                              confirmDelete({
                                id: image.id,
                                url: image.original,
                              })
                            }
                            className="rounded-xl"
                            src={image.original}
                            alt="Company Pic"
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
              {imageIdToDelete ? (
                <DeleteModal
                  isModalOpen={imageIdToDelete}
                  setIsModalOpen={setImageIdToDelete}
                  imageData={imageIdToDelete}
                  companyId={companyId}
                  headerText={"Delete Image?"}
                  confirmationText="Are you sure to delete the image?"
                  type="gallery"
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
