import React from "react";
import { Route, Switch } from "react-router-dom";
import EventDetails from "../Company/dashboard/Events/EventDetails";
import ITHubEventsNews from "./ITHubEventsNews";

import ITHubGallery from "./ITHubGallery";

const ITHub = () => {
  return (
    <Switch>
      <Route
        path="/ITHub/eventsNews"
        exact
        component={() => <ITHubEventsNews />}
      />
      <Route
        exact
        path="/ITHub/events/:companyId/:eventId/details"
        component={() => <EventDetails viewFromITHUB={true} />}
      />
      <Route path="/ITHub/gallery" component={() => <ITHubGallery />} />
    </Switch>
  );
};

export default ITHub;
