import React, { useState } from "react";
import { toast } from "react-toastify";
import { FaPlusCircle } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import firebase from "../firebase";
import { FiUploadCloud } from "react-icons/fi";

function ImageDropzone({
  setFieldValue,
  setIsUploading,
  name,
  image_url,
  path,
  className,
}) {
  const [uploading, setUploading] = useState(false);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    open,
  } = useDropzone({
    accept: "image/jpeg,image/png",
    multiple: false,
    maxSize: 10000000,
    onDropRejected: (files) => {
      toast.error(files[0].errors[0].message);
    },
    onDropAccepted: (files) => {
      console.log("files", files);
      setIsUploading(true);
      setUploading(true);
      const filePath = path;
      firebase
        .storage()
        .ref(filePath)
        .put(files[0], {
          customMetadata: {
            name: files[0].name,
            size: files[0].size,
          },
        })
        .then(() => firebase.storage().ref(filePath).getDownloadURL())
        .then((downloadURL) => {
          console.log("downloadURL", downloadURL);
          setFieldValue(name, downloadURL);
          setIsUploading(false);
          setUploading(false);
        })
        .catch((error) => {
          setIsUploading(false);
          setUploading(false);
          console.log(error);
          toast.error("Image upload failed");
        });
    },
    disabled: uploading,
  });

  return (
    <>
      <div
        {...getRootProps({
          className: `dropzone rounded-md border border-dashed border-gray-400 focus:outline-none flex justify-center items-center ${className}`,
        })}
      >
        <input {...getInputProps()} />
        <div
          className="flex flex-col items-center justify-center p-5 bg-cover h-full w-full rounded-md"
          style={{ backgroundImage: uploading ? "" : `url(${image_url})` }}
        >
          {!image_url || uploading ? (
            <div className="text-center flex justify-center items-center">
              {uploading ? (
                <>
                  <div className="spinner-grow w-6 h-6 mr-3"></div>
                  <div>Uploading...</div>
                </>
              ) : (
                <div className="flex flex-col justify-center items-center">
                  <div className="">
                    <FiUploadCloud className="w-6 h-6" />
                  </div>
                  <div>
                    {isDragActive ? (
                      <p>Drop the files here ...</p>
                    ) : (
                      <p>Drag 'n' drop or click to choose</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
export default ImageDropzone;
