import React from "react";
import { Route, Switch } from "react-router-dom";
import Navbar from "../../components/NavBar";
import CreateEditEvent from "./createEditEventORNews/CreateEditEvent";
import CreateEditNews from "./createEditEventORNews/CreateEditNews";
import EventsORNews from "./createEditEventORNews/EventsORNews";
import CreateEditJobPost from "./createEditJobPost/CreateEditJobPost";
import CreateEditManagers from "./createEditManagers/CreateEditManagers";
import Dashboard from "./dashboard/Dashboard";
import EventDetails from "./dashboard/Events/EventDetails";
import JobDetails from "./dashboard/Job/JobDetails";
import CreateEditCompany from "./createEditCompany/CreateEditCompany";
import Gallery from "../../components/Gallery";
import ViewApplicantsEnrollments from "./applicantsEnrollments";
import CreateEditCourse from "./createEditCourse/CreateEditCourse";
import CourseDetails from "./dashboard/Courses/CourseDetails";

function Company() {
  return (
    <div>
      <Navbar />
      <Switch>
        <Route
          path="/company/:companyId/dashboard/"
          component={() => <Dashboard />}
        />
        <Route
          path="/company/:companyId/job/:jobTitle/:jobPostId/applicants"
          component={() => <ViewApplicantsEnrollments />}
        />
        <Route
          path="/company/:companyId/job/create"
          component={() => <CreateEditJobPost />}
        />
        <Route
          path="/company/:companyId/job/:jobPostId/edit"
          component={() => <CreateEditJobPost />}
        />
        <Route
          path="/company/:companyId/event/create"
          component={() => <EventsORNews />}
        />
        <Route
          path="/company/:companyId/news/:newsId/edit"
          component={() => <CreateEditNews />}
        />
        <Route
          path="/company/:companyId/event/:eventId/edit"
          component={() => <CreateEditEvent />}
        />
        <Route
          path="/company/:companyId/:jobPostId/details"
          component={() => <JobDetails />}
        />
        <Route
          path="/company/:companyId/event/:eventId/details"
          component={() => <EventDetails />}
        />
        <Route
          path="/company/:companyId/edit"
          component={() => <CreateEditCompany />}
        />
        <Route
          path="/company/gallery/:companyId"
          component={() => <Gallery role="companyGallery" />}
        />
        <Route
          path="/company/:companyId/managers"
          component={() => <CreateEditManagers />}
        />
        <Route
          path="/company/:companyId/course/create"
          component={() => <CreateEditCourse />}
        />
        <Route
          path="/company/:companyId/course/:courseId/edit"
          component={() => <CreateEditCourse />}
        />
        <Route
          path="/company/:companyId/course/:courseId/details"
          component={() => <CourseDetails />}
        />
        <Route
          path="/company/:companyId/course/:courseId/:courseTitle/enrollments"
          component={() => <ViewApplicantsEnrollments />}
        />
        {/* <Redirect to="/job/dashboard" /> */}
      </Switch>
    </div>
  );
}

export default Company;
