import React, { useState } from "react";
import Header from "../../../components/Header";
import CreateEditEvent from "./CreateEditEvent";
import CreateEditNews from "./CreateEditNews";

const EventsORNews = () => {
  const [eventsORNews, seteventsORNews] = useState("event");

  return (
    <div>
      <Header view="eventOrNews" type="company" viewFromITHUB={false} />

      <div className="flex justify-center gap-5 items-center mt-10">
        <p className="font-medium">Category :</p>

        <div className="flex items-center gap-1">
          <input
            type="radio"
            name="eventsORNews"
            value="event"
            checked={eventsORNews === "event"}
            onChange={(e) => seteventsORNews(e.target.value)}
          />
          <label className="font-medium">Event</label>
        </div>

        <div className="flex items-center gap-1">
          <input
            type="radio"
            name="eventsORNews"
            value="news"
            checked={eventsORNews === "news"}
            onChange={(e) => seteventsORNews(e.target.value)}
          />
          <label className="font-medium">News</label>
        </div>
      </div>

      {eventsORNews === "event" ? <CreateEditEvent /> : <CreateEditNews />}
    </div>
  );
};

export default EventsORNews;
