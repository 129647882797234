import React, { useState } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import { db } from "../firebase";
import { toast } from "react-toastify";

const DeleteModal = ({
  isModalOpen,
  setIsModalOpen,
  imageData,
  companyId,
  headerText,
  type,
  managerId,
  setManagerDeleted,
  confirmationText,
}) => {
  const [deletingInProgress, setDeletingInProgress] = useState(false);

  const deleteImage = () => {
    setDeletingInProgress(true);
    db.collection(
      companyId
        ? `companies/${companyId}/gallery`
        : "ithub/6s9XvaNVVFACkHjbz0Pi/gallery"
    )
      .doc(imageData.id)
      .delete()
      .then(() => {
        toast.success("Image deleted successfully!");
        setDeletingInProgress(false);
        setIsModalOpen(null);
      })
      .catch((error) => {
        toast.error("Failed to delete image!");
        setDeletingInProgress(false);
        setIsModalOpen(null);
        console.log("Deleting gallery image failure", error);
      });
  };

  const deleteManager = () => {
    setDeletingInProgress(true);
    db.collection(`companies/${companyId}/managers`)
      .doc(managerId)
      .delete()
      .then(() => {
        toast.success("Manager deleted successfully!");
        setDeletingInProgress(false);
        setManagerDeleted(Math.random());
        setIsModalOpen(null);
      })
      .catch((error) => {
        toast.error("Failed to delete manager!");
        setDeletingInProgress(false);
        setIsModalOpen(null);
        console.log("Deleting manager failure", error);
      });
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(null)}
      className="inset-x-auto rounded-md overflow-auto focus:outline-none absolute my-auto lg:w-1/2 w-full px-2 flex flex-col"
      overlayClassName="transition-all ease-in-out duration-300 flex justify-center items-center bg-opacity-75 bg-black inset-0 fixed p-8 z-50"
    >
      <header className="rounded-t-md bg-black w-full py-5 px-12 text-white flex items-center justify-between">
        <div className="text-white">{headerText}</div>
        <button onClick={() => setIsModalOpen(null)}>
          <MdClose className="w-6 h-6 text-white" />
        </button>
      </header>

      <div className="bg-white flex flex-col items-center gap-3 lg:p-10 p-2">
        {type === "gallery" ? (
          <div className="flex justify-center w-1/2 border border-black">
            <img src={imageData.url} alt="Company Pic" />
          </div>
        ) : null}

        <p>{confirmationText}</p>

        <div className="flex justify-end">
          <button
            onClick={() => {
              if (type === "gallery") {
                deleteImage();
                console.log("Gallery", type);
              } else if (type === "manager") {
                deleteManager();
                console.log("Manager", type);
              }
            }}
            className="flex justify-center items-center bg-red-600 text-white rounded-lg gap-1 px-3 py-2"
          >
            <BsTrash
              className={`${deletingInProgress ? "animate-spin" : ""}`}
            />
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
