import React from "react";
import { Route, Switch } from "react-router-dom";
import NavBar from "../../components/NavBar";
import CreateEditCompany from "../Company/createEditCompany/CreateEditCompany";
import Dashboard from "./dashboard/Dashboard";
import Gallery from "../../components/Gallery";
import TalentPool from "./dashboard/talentPool";

const Admin = () => {
  return (
    <div>
      <NavBar />

      <Switch>
        <Route path="/admin/dashboard" component={() => <Dashboard />} />
        <Route
          path="/admin/gallery"
          component={() => <Gallery role="adminGallery" />}
        />

        <Route path="/admin/talentPool" component={() => <TalentPool />} />

        <Route
          path="/admin/createCompany"
          component={() => <CreateEditCompany />}
        />
      </Switch>
    </div>
  );
};

export default Admin;
