import React, { useState } from "react";
import Logo from "../../ITHUBLOGO.PNG";
import Building from "../../ITHubBuilding.jpg";
import { AiOutlineRocket, AiOutlineFund } from "react-icons/ai";
import { BiSpreadsheet } from "react-icons/bi";
import { MdEvent } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { GrConnect } from "react-icons/gr";
import {
  DiApple,
  DiCodeigniter,
  DiHeroku,
  DiIntellij,
  DiHaskell,
  DiOpera,
  DiUbuntu,
  DiYahoo,
} from "react-icons/di";
import { FcAndroidOs } from "react-icons/fc";
import DownloadApp from "../../DownloadApp.png";

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <div>
      <div className="leading-normal tracking-normal gradient">
        <nav id="header" className="lg:fixed w-full z-30 top-0 bg-white">
          <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2 px-4">
            <div className="pl-4 flex items-center">
              <a
                className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                href="/"
              >
                <div className="w-16">
                  <img src={Logo} alt="IT Hub Logo" />
                </div>
              </a>
            </div>

            <div className="block lg:hidden pr-4">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                id="nav-toggle"
                className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-800 hover:border-teal-500 appearance-none focus:outline-none"
              >
                <svg
                  className="fill-current h-3 w-3"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>

            {!isMenuOpen ? (
              <div
                className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
                id="nav-content"
              >
                <ul className="list-reset lg:flex justify-end flex-1 items-center">
                  <li className="mr-3">
                    <a
                      className="inline-block py-2 px-4 text-black font-bold no-underline"
                      href="#"
                    >
                      About Us
                    </a>
                  </li>
                  <li className="mr-3">
                    <a
                      className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                      href="#"
                    >
                      Corporations
                    </a>
                  </li>
                  <li className="mr-3">
                    <a
                      className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                      href="/ITHub/eventsNews"
                    >
                      Events/News
                    </a>
                  </li>
                  <li className="mr-3">
                    <a
                      className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                      href="/login"
                    >
                      Company Login
                    </a>
                  </li>
                  <li className="mr-3">
                    <a
                      className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                      href="/login"
                    >
                      TASK Login
                    </a>
                  </li>
                </ul>
                {/* <button
                id="navAction"
                className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75"
              >
                Action
              </button> */}
              </div>
            ) : null}
          </div>
        </nav>

        {isMenuOpen ? (
          <div className="flex flex-col lg:hidden">
            <ul className="list-reset justify-end flex-1 items-center">
              <li className="mr-3">
                <a
                  className="inline-block py-2 px-4 text-black font-bold no-underline"
                  href="#"
                >
                  About Us
                </a>
              </li>
              <li className="mr-3">
                <a
                  className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                  href="#"
                >
                  Corporations
                </a>
              </li>
              <li className="mr-3">
                <a
                  className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                  href="/ITHub/eventsNews"
                >
                  Events/News
                </a>
              </li>
              <li className="mr-3">
                <a
                  className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                  href="/login"
                >
                  Company Login
                </a>
              </li>
              <li className="mr-3">
                <a
                  className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"
                  href="/login"
                >
                  TASK Login
                </a>
              </li>
            </ul>
          </div>
        ) : null}

        <div
          style={{ backgroundColor: "#3DDC84" }}
          className="flex items-center justify-between gap-20 text-white py-20 px-2 lg:p-20 pb-0 lg:pb-0"
        >
          <div>
            <img
              src={DownloadApp}
              alt="App Download Pic"
              //height={100}
              width={200}
            />
          </div>
          <div className="flex flex-col">
            <p className="lg:text-2xl text-lg mb-5">
              Khammam IT Hub Application. Now available on android.
            </p>
            <div>
              <a
                className="bg-blue-500 py-3 rounded-lg flex justify-center cursor-pointer lg:text-xl  mb-5 hover:shadow-lg"
                href="https://play.google.com/store/apps/details?id=com.kmm.ithub"
              >
                Download Now
              </a>
            </div>
          </div>
        </div>

        <div className=" bg-red-500 text-white">
          <div className="container px-8 mx-auto flex justify-between">
            <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
              <h1 className="mt-4 text-5xl font-bold leading-tight">
                ENABLING AND EMPOWERING
              </h1>
              <p className="leading-normal text-2xl mb-8">
                AN ECOSYSTEM HUNGRY FOR INNOVATION
              </p>
            </div>

            <div className="w-1/4 p-5 hidden lg:block opacity-40">
              <img src={Building} alt="IT Hub Building" />
            </div>
          </div>
        </div>

        <section className="bg-white border-b py-10">
          <div className="container max-w-5xl mx-auto m-8">
            <h3 className="w-full my-2 text-3xl leading-tight text-center text-gray-800">
              The website is currently undergoing maintenance, please check back
              soon. In the meantime, please checkout the IT Hub Khammam Android
              Application.
            </h3>

            {/* <div className="grid grid-cols-2 lg:grid-cols-5 gap-5 my-10">
              <div className="flex gap-4 items-center justify-center">
                <AiOutlineRocket className="text-8xl" />
                <div>
                  <p className="text-red-600 text-3xl font-semibold">1,100+</p>
                  <p className="text-gray-500 text-lg">Startups Impacted</p>
                </div>
              </div>
              <div className="flex gap-4 items-center justify-center">
                <BiSpreadsheet className="text-8xl" />
                <div>
                  <p className="text-red-600 text-3xl font-semibold">75+</p>
                  <p className="text-gray-500 text-lg">Exclusive Programs</p>
                </div>
              </div>
              <div className="flex gap-4 items-center justify-center">
                <MdEvent className="text-7xl" />
                <div>
                  <p className="text-red-600 text-3xl font-semibold">850+</p>
                  <p className="text-gray-500 text-lg">Events</p>
                </div>
              </div>
              <div className="flex gap-4 items-center justify-center">
                <FaUsers className="text-6xl" />
                <div>
                  <p className="text-red-600 text-3xl font-semibold">110K+</p>
                  <p className="text-gray-500 text-lg">T-Hub Visitors</p>
                </div>
              </div>
              <div className="flex gap-4 items-center justify-center">
                <GrConnect className="text-6xl" />
                <div>
                  <p className="text-red-600 text-3xl font-semibold">325+</p>
                  <p className="text-gray-500 text-lg">
                    International Connects
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </section>

        {/* <section className="bg-gray-100 border-b py-8 px-10">
          <div className="flex flex-col lg:flex-row gap-5 ">
            <div className="w-full lg:w-1/3">
              <h1 className="my-2 text-3xl leading-tight text-gray-800">
                MEET OUR COMPANIES
              </h1>
              <p className="text-sm text-gray-400 font-medium">
                We are building a culture of innovation through collaboration
                with a network of investors, mentors, service providers,
                ecosystem enablers and the government.
              </p>
            </div>
            <div className="grid w-full grid-cols-2 lg:grid-cols-4 gap-2">
              <div className="p-5 bg-white flex justify-center">
                <DiApple className="text-6xl" />
              </div>
              <div className="p-5 bg-white flex justify-center">
                <DiCodeigniter className="text-6xl text-red-600" />
              </div>
              <div className="p-5 bg-white flex justify-center">
                <DiHeroku className="text-6xl text-blue-700" />
              </div>
              <div className="p-5 bg-white flex justify-center">
                <DiIntellij className="text-6xl" />
              </div>
              <div className="p-5 bg-white flex justify-center">
                <DiHaskell className="text-6xl" />
              </div>
              <div className="p-5 bg-white flex justify-center">
                <DiOpera className="text-6xl text-red-600" />
              </div>
              <div className="p-5 bg-white flex justify-center">
                <DiUbuntu className="text-6xl text-red-400" />
              </div>
              <div className="p-5 bg-white flex justify-center">
                <DiYahoo className="text-6xl text-purple-700" />
              </div>
            </div>
          </div>
        </section>  */}
      </div>
    </div>
  );
};

export default LandingPage;
