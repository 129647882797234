import React, { useContext, useEffect, useState } from "react";
import {
  AiOutlineHome,
  AiOutlineHourglass,
  AiOutlinePlayCircle,
} from "react-icons/ai";
import { FaMoneyBill } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { MdDateRange } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../../components/Header";
import Loading from "../../../../components/Loading";
import { db, FirebaseContext } from "../../../../firebase";

const JobDetails = () => {
  let { jobPostId, companyId } = useParams();

  const [jobDetails, setJobDetails] = useState(null);
  const [jobPostDetailsError, setJobPostDetailsError] = useState(false);

  const fetchJobDetail = async () => {
    let data = await db
      .collection(`companies/${companyId}/jobs`)
      .doc(jobPostId)
      .get()
      .catch(() => {
        toast.error("Error while fetching Job Post Info!");
      });

    if (data && data.data()) {
      console.log("Data", data.data());
      setJobDetails(data.data());
    } else {
      toast.error("Job Post Details not available");
      setJobPostDetailsError(true);
    }
  };

  useEffect(() => {
    fetchJobDetail();
  }, []);

  if (jobPostDetailsError) {
    return (
      <div className="flex m-10 justify-center">
        <p>Failed to load job post or company details!...</p>
      </div>
    );
  }

  if (!jobDetails) {
    return <Loading />;
  }

  return (
    <div>
      <Header view="jobDetails" roleName={jobDetails.jobTitle} />

      <div className="lg:mx-28 lg:w-7/12 border px-5 my-5 rounded-xl">
        <div className="flex-col gap-4 px-2 mb-5 pb-5 border-b">
          <div className="lg:py-5">
            <p className="text-xl font-semibold">{jobDetails.jobTitle}</p>
            <p className="text-sm text-gray-500">{jobDetails.company_name}</p>
          </div>

          {jobDetails.jobType === "inOffice" ? (
            <div className="flex gap-2 mt-4 lg:mt-0 items-center">
              <GoLocation />
              <p>Office</p>
            </div>
          ) : (
            <div className="flex gap-2 mt-4 lg:mt-0 items-center">
              <AiOutlineHome />
              <p>Work From Home</p>
            </div>
          )}

          <div className="grid grid-cols-2 lg:flex lg:gap-20 w-full gap-5 my-5">
            <div>
              <div className="flex items-center gap-2  text-gray-500 text-sm">
                <AiOutlinePlayCircle />
                START DATE
              </div>
              <p className="text-sm">
                {`${new Date(jobDetails.startsAt.seconds * 1000).getDate()} / ${
                  new Date(jobDetails.startsAt.seconds * 1000).getMonth() + 1
                } / ${new Date(
                  jobDetails.startsAt.seconds * 1000
                ).getFullYear()}`}
              </p>
            </div>
            <div>
              <div className="flex items-center gap-2 text-gray-500 text-sm">
                <MdDateRange />
                DURATION
              </div>
              <p className="text-sm">3 Months</p>
            </div>
            <div>
              <div className="flex items-center gap-2 text-gray-500 text-sm">
                <FaMoneyBill />
                CTC
              </div>
              <p className="text-sm">
                ₹ {jobDetails.minCTC}-{jobDetails.maxCTC} LPA
              </p>
            </div>
            <div>
              <div className="flex items-center gap-2 text-gray-500 text-sm">
                <AiOutlineHourglass />
                APPLY BY
              </div>
              <p className="text-sm">
                {`${new Date(jobDetails.endsAt.seconds * 1000).getDate()} / ${
                  new Date(jobDetails.endsAt.seconds * 1000).getMonth() + 1
                } / ${new Date(
                  jobDetails.endsAt.seconds * 1000
                ).getFullYear()}`}
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-2 pb-5">
            <p className="text-xl">Number of openings</p>
            <p className="text-sm">{jobDetails.numberOfOpenings}</p>
          </div>
        </div>

        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-3">
            <p className="text-xl">
              About {jobDetails ? jobDetails.name : "N/A"}
            </p>
            <p className="text-sm">{jobDetails ? jobDetails.aboutUs : "N/A"}</p>
          </div>
          <p className="text-xl">About the job</p>
          <div className="text-sm">{jobDetails.jobDescription}</div>
          <div className="flex flex-col gap-3">
            <p className="text-xl">Skill(s) required</p>
            <div className="flex flex-wrap gap-4 text-gray-700">
              {jobDetails.skills && jobDetails.skills.length > 0
                ? jobDetails.skills.map((skill) => {
                    return (
                      <p className="bg-green-200 rounded-full px-4 py-2">
                        {skill}
                      </p>
                    );
                  })
                : "N/A"}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-xl">Who can apply</p>
            <div className="text-sm flex flex-col gap-2">
              <p>Only those candidates can apply who:</p>
              <p>{jobDetails.candidatePreference}</p>
            </div>
          </div>
          <div className="flex flex-col gap-3 mb-5">
            <p className="text-xl">Perks</p>
            <div className="flex flex-wrap gap-4 text-gray-700">
              {jobDetails.perks && jobDetails.perks.length > 0
                ? jobDetails.perks.map((perk) => {
                    return (
                      <p className="bg-blue-200 flex px-3 justify-center items-center py-2 rounded-full lg:px-4 lg:py-2">
                        {perk}
                      </p>
                    );
                  })
                : "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
