import React, { useState } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { useParams } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import RequiredAsterisk from "../../../components/RequiredAsterisk";
import * as Yup from "yup";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { db } from "../../../firebase";
import { toast } from "react-toastify";
const schema = Yup.object().shape({
  name: Yup.string().nullable().required("Required"),
  email: Yup.string().nullable().required("Required"),
});

const AddNewManagerModal = ({
  isModalOpen,
  setIsModalOpen,
  setNewManagerAdded,
}) => {
  let { companyId } = useParams();
  const [addingNewManager, setAddingNewManager] = useState(false);
  return (
    <Modal
      isOpen={isModalOpen}
      //style={customStyles}
      onRequestClose={() => setIsModalOpen(false)}
      className="inset-x-auto rounded-md overflow-auto focus:outline-none absolute top-0 mt-20 lg:w-1/2 w-full px-2 flex flex-col"
      overlayClassName="transition-all ease-in-out duration-300 flex justify-center items-center bg-opacity-75 bg-black inset-0 fixed p-8 z-50"
    >
      <header className="rounded-t-md bg-black w-full py-5 px-5 text-white flex items-center justify-between">
        <div className="text-white">Add new manager</div>
        <button onClick={() => setIsModalOpen(false)}>
          <MdClose className="w-6 h-6 text-white" />
        </button>
      </header>

      <div
        className="bg-white flex flex-col gap-3 p-5 overflow-y-auto"
        style={{ maxHeight: 500 }}
      >
        <Formik
          initialValues={{
            name: null,
            email: null,
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            setAddingNewManager(true);

            let finalData = {
              ...values,
              company_id: companyId,
            };

            db.collection(`companies/${companyId}/managers`)
              .doc(values.email)
              .set(finalData)
              .then(() => {
                toast.success("New manager created successfully!");
                setAddingNewManager(false);
                setIsModalOpen(false);
                setNewManagerAdded(Math.random());
              })
              .catch((error) => {
                console.log(error);
                toast.error("Failed to add manager");
                setAddingNewManager(false);
                setIsModalOpen(false);
                setNewManagerAdded(Math.random());
              });
          }}
        >
          {({ values }) => {
            return (
              <Form className="flex flex-col gap-6">
                <div>
                  <div className="flex gap-1 items-center">
                    <p>Manager Name</p>
                    <RequiredAsterisk />
                  </div>
                  <Field
                    className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                    placeholder="Manager Name"
                    name="name"
                  />
                  <ErrorMessage
                    name="name"
                    render={(msg) => (
                      <div className="text-red-600 text-sm">{msg}</div>
                    )}
                  />
                </div>

                <div>
                  <div className="flex gap-1 items-center">
                    <p>Manager Email</p>
                    <RequiredAsterisk />
                  </div>
                  <Field
                    className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                    placeholder="Manager Email"
                    name="email"
                  />
                  <ErrorMessage
                    name="email"
                    render={(msg) => (
                      <div className="text-red-600 text-sm">{msg}</div>
                    )}
                  />
                </div>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={addingNewManager}
                    className={`px-6 py-2 bg-blue-600 rounded-lg text-white focus:outline-none`}
                  >
                    {addingNewManager ? (
                      <div className="flex gap-2 items-center justify-center">
                        <AiOutlineLoading3Quarters className=" animate-spin" />
                        <p>Submitting...</p>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddNewManagerModal;
