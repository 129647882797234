import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Header from "../../../components/Header";
import { useParams } from "react-router-dom";
import { db, storageRef } from "../../../firebase";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import * as Yup from "yup";
import RequiredAsterisk from "../../../components/RequiredAsterisk";
const schema = Yup.object().shape({
  name: Yup.string().nullable().required("Required"),
  aboutUs: Yup.string().nullable().required("Required"),
  email: Yup.string().nullable().required("Required"),
  address: Yup.string().nullable().required("Required"),
  phoneNumber: Yup.number().nullable().required("Required"),
});

const EditCompanyDetails = () => {
  const [companyDetails, setCompanyDetails] = useState(null);
  const [companyDetailsError, setCompanyDetailsError] = useState(false);
  let { companyId } = useParams();
  const [image, setimage] = useState(null);
  const [updatingCompanyDetails, setUpdatingCompanyDetails] = useState(false);

  const fetchCompanyDetails = async () => {
    let data = await db
      .collection("companies")
      .doc(companyId)
      .get()
      .catch((error) => {
        toast.error("Failed to fetch company details..");
        console.log("Edit company details error", error);
        setCompanyDetailsError(true);
      });

    if (data && data.data()) {
      console.log("Company Details", data.data());
      setCompanyDetails(data.data());
    } else {
      setCompanyDetails(null);
      toast.error("Company details not found!...");
      setCompanyDetailsError(true);
      return false;
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchCompanyDetails();
    }
  }, [updatingCompanyDetails]);

  if (companyDetailsError) {
    return (
      <div className="flex m-10 justify-center text-base">
        <p>Not data found!...</p>
      </div>
    );
  }

  const uploadimage = async () => {
    setUpdatingCompanyDetails(true);
    var d = new Date();
    var n = d.getTime();
    const fileRef = storageRef
      .ref(
        companyId
          ? `companyProfilePics/${companyId}`
          : `companyProfilePics/adminUploadedPics`
      )
      .child(image.name + n);
    await fileRef.put(image).catch((error) => {
      toast.error("Failed to upload company profile picture!...");
      console.log("Firebase image upload failure", error);
      return false;
    });
    let url = await fileRef.getDownloadURL();
    console.log(url);
    return url;
  };

  return (
    <div>
      <Header view="editCompany" />

      {!companyId || companyDetails ? (
        <div className="lg:px-28 lg:pt-10">
          <Formik
            initialValues={
              companyDetails
                ? {
                    name: companyDetails.name,
                    aboutUs: companyDetails.aboutUs,
                    phoneNumber: companyDetails.phoneNumber,
                    address: companyDetails.address,
                    email: companyDetails.email,
                    profile_pic: companyDetails.profile_pic,
                  }
                : {
                    name: "",
                    aboutUs: "",
                    phoneNumber: "",
                    address: "",
                    email: "",
                    profile_pic: "",
                  }
            }
            onSubmit={async (values) => {
              if (companyId) {
                if (image) {
                  let imageURL = await uploadimage();
                  if (imageURL) {
                    let finalData = {
                      name: values.name,
                      aboutUs: values.aboutUs,
                      phoneNumber: values.phoneNumber,
                      address: values.address,
                      email: values.email,
                      profile_pic: imageURL,
                    };

                    db.collection("companies")
                      .doc(companyId)
                      .update(finalData)
                      .then(() => {
                        toast.success(
                          "Company Profile updated successfully!..."
                        );
                        setUpdatingCompanyDetails(false);
                      })
                      .catch((error) => {
                        console.log(error);
                        setUpdatingCompanyDetails(false);

                        toast.error("Failed to update company profile!");
                      });
                  }
                } else {
                  setUpdatingCompanyDetails(true);
                  let finalData = {
                    name: values.name,
                    aboutUs: values.aboutUs,
                    phoneNumber: values.phoneNumber,
                    address: values.address,
                    email: values.email,
                  };

                  db.collection("companies")
                    .doc(companyId)
                    .update(finalData)
                    .then(() => {
                      toast.success("Company Profile updated successfully!...");
                      setUpdatingCompanyDetails(false);
                    })
                    .catch((error) => {
                      console.log(error);
                      setUpdatingCompanyDetails(false);

                      toast.error("Failed to update company profile!");
                    });
                }
              } else {
                if (image) {
                  let imageURL = await uploadimage();
                  if (imageURL) {
                    let finalData = {
                      name: values.name,
                      aboutUs: values.aboutUs,
                      phoneNumber: values.phoneNumber,
                      address: values.address,
                      email: values.email,
                      profile_pic: imageURL,
                    };

                    db.collection("companies")

                      .add(finalData)
                      .then(() => {
                        toast.success("Company added successfully!...");
                        setUpdatingCompanyDetails(false);
                      })
                      .catch((error) => {
                        console.log(error);
                        setUpdatingCompanyDetails(false);

                        toast.error("Failed to add company!");
                      });
                  }
                } else {
                  setUpdatingCompanyDetails(true);
                  let finalData = {
                    name: values.name,
                    aboutUs: values.aboutUs,
                    phoneNumber: values.phoneNumber,
                    address: values.address,
                    email: values.email,
                    profile_pic: "",
                  };

                  db.collection("companies")
                    .add(finalData)
                    .then(() => {
                      toast.success("Company added successfully!...");
                      setUpdatingCompanyDetails(false);
                    })
                    .catch((error) => {
                      console.log(error);
                      setUpdatingCompanyDetails(false);

                      toast.error("Failed to add company!");
                    });
                }
              }
            }}
            val
            validationSchema={schema}
          >
            {({ values, errors }) => {
              return (
                <Form className="lg:w-10/12 mx-auto px-2 py-5 lg:px-0 lg:py-0">
                  <div className="flex flex-col gap-10">
                    <div>
                      <p className="text-xl font-medium">Company details</p>
                      <div className="p-5 mt-2 flex flex-col gap-4 rounded-lg border">
                        <div>
                          <div className="flex gap-1 items-center">
                            <p className="font-medium">Company Name</p>
                            <RequiredAsterisk />
                          </div>

                          <Field
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="Company Name"
                            name="name"
                          />
                          <ErrorMessage
                            name="name"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>

                        <div>
                          <div className="flex gap-1 items-center">
                            <p className="font-medium">About Us</p>
                            <RequiredAsterisk />
                          </div>

                          <Field
                            as="textarea"
                            rows={6}
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="Write something about your company..."
                            name="aboutUs"
                          />
                          <ErrorMessage
                            name="aboutUs"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>

                        <div>
                          <div className="flex gap-1 items-center">
                            <p className="font-medium">Email Id</p>
                            <RequiredAsterisk />
                          </div>

                          <Field
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="e.g mycompany@gmail.com"
                            name="email"
                          />
                          <ErrorMessage
                            name="email"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>

                        <div>
                          <div className="flex gap-1 items-center">
                            <p className="font-medium">Address</p>

                            <RequiredAsterisk />
                          </div>
                          <Field
                            as="textarea"
                            rows={6}
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="Where is your company at?"
                            name="address"
                          />
                          <ErrorMessage
                            name="address"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>

                        <div>
                          <div className="flex gap-1 items-center">
                            <p className="font-medium">Phone Number</p>

                            <RequiredAsterisk />
                          </div>
                          <Field
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="Contact Number"
                            name="phoneNumber"
                          />
                          <ErrorMessage
                            name="phoneNumber"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>

                        <div>
                          <p className="font-medium">Company Logo</p>
                          <div className="flex p-2 border">
                            <input
                              type="file"
                              accept="image/x-png,image/image/jpeg/png"
                              onChange={(e) => {
                                //console.log("Selected File", e.target.files);
                                setimage(e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>

                        {values.profile_pic ? (
                          <div>
                            <p className="font-medium">
                              Present Profile Picture
                            </p>
                            <div className="flex p-5 justify-center bg-gray-200 border">
                              <img
                                src={values.profile_pic}
                                alt={"Company Logo"}
                                height="500"
                                width="500"
                              />
                            </div>
                          </div>
                        ) : null}

                        <div className="flex justify-end">
                          <button
                            type="submit"
                            disabled={updatingCompanyDetails}
                            className={`px-6 py-2 ${
                              updatingCompanyDetails
                                ? "bg-gray-300"
                                : "bg-blue-600"
                            } rounded-lg text-white focus:outline-none`}
                          >
                            {updatingCompanyDetails ? (
                              <div className="flex gap-2 items-center justify-center">
                                <AiOutlineLoading3Quarters className=" animate-spin" />
                                <p>Submitting...</p>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default EditCompanyDetails;
