import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { db, FirebaseContext } from "../../../../firebase";
import Loading from "../../../../components/Loading";
import { useParams } from "react-router-dom";
import { BsGear } from "react-icons/bs";
import { BsFillEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import Switch from "react-switch";
import { Base64 } from "js-base64";

import {
  AiOutlineHourglass,
  AiOutlineLoading3Quarters,
  AiOutlinePlayCircle,
} from "react-icons/ai";
import { format } from "date-fns";

const ViewEnrollments = ({ courseId, courseTitle }) => {
  const [count, setCount] = useState(null);
  let { companyId } = useParams();

  const fetchApplicantsCount = async () => {
    let data = await db
      .collection(`courses/${courseId}/enrollments`)
      .get()
      .catch(() => {
        toast.error("Failed to fetch applicants count!");
      });

    if (data && data.docs) {
      if (data.docs.length > 0) {
        setCount(data.docs.length);
      } else {
        setCount("0");
      }
    } else {
      toast.error("Cannot find the applicants!");
    }
  };

  useEffect(() => {
    fetchApplicantsCount();
  }, []);

  return (
    <div>
      <Link
        to={`/company/${companyId}/course/${courseId}/${courseTitle}/enrollments/all`}
        className=" py-2  bg-blue-500 px-4 text-white rounded-md flex items-center text-sm"
      >
        View Enrollments (
        {count ? (
          count
        ) : (
          <AiOutlineLoading3Quarters className=" animate-spin text-sm font-semibold" />
        )}
        )
      </Link>
    </div>
  );
};

const CourseCard = ({ data, setCourseStatusChanged }) => {
  console.log("Post Details", data);
  let { companyId } = useParams();
  const [status, setStatus] = useState(data.status);

  const changeStatus = (status) => {
    db.collection(`courses`)
      .doc(data.id)
      .update({
        status: status,
      })
      .then(() => {
        toast.success("Course Status changed!");
        setCourseStatusChanged(Math.random());
      })
      .catch((error) => {
        console.log("Error", error);
        toast.error("Failed to update status!");
      });
  };

  return (
    <div className="flex flex-col bg-white p-4 border rounded-md shadow-md">
      <div className="flex lg:flex-row lg:justify-between items-start flex-col-reverse">
        <div>
          <div className="flex gap-3 items-center">
            <p className="text-base font-semibold text-gray-700 mb-1 flex-nowrap">
              {data.title}
            </p>
            <div>
              <div
                className={`${
                  data.status
                    ? "bg-green-100 text-green-500"
                    : "bg-red-100 text-red-600"
                } px-2 rounded-xl`}
              >
                <p className="text-sm">{data.status ? "Open" : "Closed"}</p>
              </div>
            </div>
          </div>
          {/* <p className="text-sm font-medium text-gray-400">
            {data.company_name}
          </p> */}
        </div>
        {/* <div>
          <img
            src={profilePic}
            alt="Company Logo"
            height="100"
            width="100"
          ></img>
        </div> */}
      </div>

      <div className="flex items-center flex-wrap pt-5 gap-5 lg:gap-10">
        <div>
          <div className="flex items-center gap-2  text-gray-500 text-xs">
            <AiOutlinePlayCircle />
            STARTS
          </div>
          <p className="text-xs lg:text-base">
            {`${format(new Date(data.startsAt.seconds * 1000), "P")}`}
          </p>
        </div>

        <div>
          <div className="flex items-center gap-2 text-gray-500 text-xs">
            <AiOutlineHourglass />
            APPLY BY
          </div>
          <p className="text-xs lg:text-base">
            {`${format(new Date(data.endsAt.seconds * 1000), "P")}`}
          </p>
        </div>
        <div>
          <div className="flex items-center gap-2 text-gray-500 text-xs">
            <BsGear />
            DIFFICULTY
          </div>
          <p className="text-xs lg:text-base">{data.difficulty}</p>
        </div>
      </div>

      <div className="flex items-center justify-between mt-6">
        <div>
          <ViewEnrollments courseId={data.id} courseTitle={data.title} />
        </div>

        <div className="flex gap-3">
          <Link
            to={`/company/${companyId}/course/${data.id}/edit`}
            className=" py-2 bg-blue-100 px-4 rounded-md flex items-center gap-2 text-sm"
          >
            <MdEdit className="text-xl text-blue-600" />
          </Link>
          <Link
            to={`/company/${companyId}/course/${data.id}/details`}
            className=" py-2 bg-blue-100 px-4 rounded-md flex items-center gap-2 text-sm"
          >
            <BsFillEyeFill className="text-xl text-blue-600" />
          </Link>
        </div>
      </div>
      <div className="flex gap-10 items-center mt-5">
        <p className="text-base font-medium">Course Status :</p>
        <div className="flex items-center gap-2">
          <p className="text-red-500 text-sm lg:text-lg font-medium">Close</p>
          <Switch
            type="checkbox"
            name="status"
            onChange={(checked) => {
              setStatus(!status);
              console.log("Checked", checked);
              changeStatus(checked);
            }}
            checked={status}
          />
          <p className="text-green-500 text-sm lg:text-lg font-medium">Open</p>
        </div>
      </div>
    </div>
  );
};

const Courses = () => {
  //const { currentUser } = useContext(FirebaseContext);
  const [courses, setCourses] = useState(null);
  const [courseFetchError, setCourseFetchError] = useState(false);
  const [courseStatusChanged, setCourseStatusChanged] = useState(null);

  //let { companyId } = useParams();

  const fetchAllCourses = async () => {
    let data = await db
      .collection(`courses`)
      .get()
      .catch((error) => {
        console.log(error);
        setCourseFetchError(true);
        toast.error("Failed to fetch news!");
      });

    if (data && data.docs && data.docs.length > 0) {
      setCourses(
        data.docs.map((item) => {
          return {
            ...item.data(),
            id: item.id,
          };
        })
      );
    } else {
      setCourses([]);
    }
  };

  useEffect(() => {
    fetchAllCourses();
  }, [courseStatusChanged]);

  if (courseFetchError) {
    return (
      <div className="flex justify-center m-10 text-lg text-red-600">
        <p>Error while fetching news!...</p>
      </div>
    );
  }

  if (!courses) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  // if (courses && courses.length === 0) {
  //   return (
  //     <div className="flex justify-center mx-10">
  //       <p>No courses available!...</p>
  //     </div>
  //   );
  // }

  return (
    <div>
      {courses.length > 0 ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 pb-5 ">
          {courses.map((course, index) => (
            <div key={course.id}>
              <CourseCard
                data={course}
                setCourseStatusChanged={setCourseStatusChanged}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="flex justify-center mx-10 my-5">
          <p>No courses available!...</p>
        </div>
      )}
    </div>
  );
};

export default Courses;
