import React, { useContext, useEffect, useState } from "react";
import {
  AiOutlineHome,
  AiOutlineHourglass,
  AiOutlinePlayCircle,
} from "react-icons/ai";
import { FaMoneyBill } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { MdDateRange } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../../components/Header";
import Loading from "../../../../components/Loading";
import { db, FirebaseContext } from "../../../../firebase";
import { BsGear } from "react-icons/bs";

const CourseDetails = () => {
  let { courseId } = useParams();
  const { currentUser: currentUserData } = useContext(FirebaseContext);

  const [courseDetails, setcourseDetails] = useState(null);
  const [courseDetailsError, setcourseDetailsError] = useState(false);

  const fetchCourseDetail = async () => {
    let data = await db
      .collection(`courses`)
      .doc(courseId)
      .get()
      .catch(() => {
        toast.error("Error while fetching Job Post Info!");
      });

    if (data && data.data()) {
      setcourseDetails(data.data());
    } else {
      toast.error("Job Post Details not available");
      setcourseDetailsError(true);
    }
  };
  useEffect(() => {
    fetchCourseDetail();
  }, []);

  if (courseDetailsError) {
    return (
      <div className="flex m-10 justify-center">
        <p>Failed to load job post details!...</p>
      </div>
    );
  }

  if (!courseDetails) {
    return <Loading />;
  }

  return (
    <div>
      <Header
        view="courseDetails"
        roleName={courseDetails.title}
        type="company"
      />

      <div className="lg:mx-28 lg:w-7/12 border px-5 my-5 rounded-xl">
        <div className="flex-col gap-4 px-2 mb-5 pb-5 border-b">
          <div className="lg:py-5">
            <p className="text-xl font-semibold">{courseDetails.title}</p>
            {/* <p className="text-sm text-gray-500">
              {courseDetails.company_name}
            </p> */}
          </div>

          <div className="grid grid-cols-2 lg:flex lg:gap-20 w-full gap-5 my-5">
            <div>
              <div className="flex items-center gap-2  text-gray-500 text-sm">
                <AiOutlinePlayCircle />
                START DATE
              </div>
              <p className="text-sm">
                {new Date(courseDetails.startsAt.seconds * 1000)
                  .toUTCString()
                  .slice(0, 16)}
              </p>
            </div>
            <div>
              <div className="flex items-center gap-2 text-gray-500 text-sm">
                <AiOutlineHourglass />
                APPLY BY
              </div>
              <p className="text-sm">
                {new Date(courseDetails.endsAt.seconds * 1000)
                  .toUTCString()
                  .slice(0, 16)}
              </p>
            </div>

            <div>
              <div className="flex items-center gap-2 text-gray-500 text-sm">
                <BsGear />
                DIFFICULTY
              </div>
              <p className="text-sm">{courseDetails.difficulty}</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-3">
            <p className="text-xl">About Course</p>
            <p className="text-sm">{courseDetails.courseDescription}</p>
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-xl">Pre-Requisites</p>
            <div className="text-sm flex flex-col gap-2">
              <p>{courseDetails.preRequisites}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;
