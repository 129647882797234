import React, { useEffect, useState, useContext } from "react";
import { MdEdit, MdAddCircleOutline } from "react-icons/md";
import { AiOutlinePlayCircle, AiOutlineHourglass } from "react-icons/ai";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import Header from "../../../components/Header";
import { db, FirebaseContext } from "../../../firebase";
import { BsFillEyeFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Events from "./Events/EventsList";
import News from "./News/News";
import Courses from "./Courses/Courses";
import { format } from "date-fns";
import Switch from "react-switch";
import PlaceHolder from "../../../PlaceHolder.png";
import { FaUsers } from "react-icons/fa";
import Loading from "../../../components/Loading";
import { Base64 } from "js-base64";

const ViewApplicants = ({ company_id, jobPostId, jobTitle }) => {
  const [count, setCount] = useState(null);

  const fetchApplicantsCount = async () => {
    let data = await db
      .collection(`companies/${company_id}/jobs/${jobPostId}/applicants`)
      .get()
      .catch(() => {
        toast.error("Failed to fetch applicants count!");
      });

    if (data && data.docs) {
      if (data.docs.length > 0) {
        setCount(data.docs.length);
      } else {
        setCount("0");
      }
    } else {
      toast.error("Cannot find the applicants!");
    }
  };
  useEffect(() => {
    fetchApplicantsCount();
  }, []);

  return (
    <div>
      <Link
        to={`/company/${company_id}/job/${Base64.encode(
          jobTitle
        )}/${jobPostId}/applicants/all`}
        className=" py-2  bg-blue-500 px-4 text-white rounded-md flex items-center text-sm"
      >
        View Applicants (
        {count ? (
          count
        ) : (
          <AiOutlineLoading3Quarters className=" animate-spin text-sm font-semibold" />
        )}
        )
      </Link>
    </div>
  );
};

const JobPost = ({ data, setJobStatusChanged, companyId }) => {
  //console.log("Post Details", data);
  const [status, setStatus] = useState(data.status);

  const changeStatus = (status) => {
    db.collection(`companies/${companyId}/jobs`)
      .doc(data.id)
      .update({
        status: status,
      })
      .then(() => {
        toast.success("Job Status changed!");
        setJobStatusChanged(Math.random());
      })
      .catch((error) => {
        console.log("Error", error);
        toast.error("Failed to update status!");
      });
  };

  return (
    <div className="flex flex-col bg-white p-4 border rounded-md shadow-md">
      <div className="flex lg:flex-row lg:justify-between items-start flex-col-reverse">
        <div>
          <div className="flex gap-3 items-center">
            <p className="text-base font-semibold text-gray-700 mb-1 flex-nowrap">
              {data.jobTitle}
            </p>
            <div>
              <div
                className={`${
                  data.status
                    ? "bg-green-100 text-green-500"
                    : "bg-red-100 text-red-600"
                } px-2 rounded-xl`}
              >
                <p className="text-sm">{data.status ? "Open" : "Closed"}</p>
              </div>
            </div>
          </div>
          <p className="text-sm font-medium text-gray-400">
            {data.company_name}
          </p>
        </div>
        <div>
          <img
            src={data.profile_pic ? data.profile_pic : PlaceHolder}
            alt="Company Logo"
            height="100"
            width="100"
          ></img>
        </div>
      </div>

      <div className="flex items-center pt-5 gap-5 lg:gap-10">
        <div>
          <div className="flex items-center gap-2  text-gray-500 text-xs">
            <AiOutlinePlayCircle />
            STARTS
          </div>
          <p className="text-xs lg:text-base">
            {`${format(new Date(data.startsAt.seconds * 1000), "P")}`}
          </p>
        </div>
        <div>
          <div className="flex items-center gap-2 text-gray-500 text-xs">
            <FaRegMoneyBillAlt />
            STIPEND
          </div>
          <p className="text-xs lg:text-base">
            ₹ {data.minCTC}-{data.maxCTC} LPA
          </p>
        </div>
        <div>
          <div className="flex items-center gap-2 text-gray-500 text-xs">
            <AiOutlineHourglass />
            APPLY BY
          </div>
          <p className="text-xs lg:text-base">
            {`${format(new Date(data.endsAt.seconds * 1000), "P")}`}
          </p>
        </div>
      </div>

      <div className="flex items-center justify-between mt-6">
        <div className="flex gap-5 items-center">
          <ViewApplicants
            company_id={data.company_id}
            jobPostId={data.id}
            jobTitle={data.jobTitle}
          />
        </div>

        <div className="flex gap-3">
          <Link
            to={`/company/${companyId}/job/${data.id}/edit`}
            className=" py-2 bg-blue-100 px-4 rounded-md flex items-center gap-2 text-sm"
          >
            <MdEdit className="text-xl text-blue-600" />
          </Link>
          <Link
            to={`/company/${companyId}/${data.id}/details`}
            className=" py-2 bg-blue-100 px-4 rounded-md flex items-center gap-2 text-sm"
          >
            <BsFillEyeFill className="text-xl text-blue-600" />
          </Link>
        </div>
      </div>

      <div className="flex gap-10 items-center mt-5">
        <p className="text-base font-medium">Job Status :</p>
        <div className="flex items-center gap-2">
          <p className="text-red-500 text-sm lg:text-lg font-medium">Close</p>
          <Switch
            type="checkbox"
            name="status"
            onChange={(checked) => {
              setStatus(!status);
              console.log("Checked", checked);
              changeStatus(checked);
            }}
            checked={status}
          />
          <p className="text-green-500 text-sm lg:text-lg font-medium">Open</p>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  //const { currentUser } = useContext(FirebaseContext);

  const [jobStatusChanged, setJobStatusChanged] = useState(null);
  const [jobPosts, setJobPosts] = useState(null);
  let { companyId } = useParams();
  const [companyDetails, setCompanyDetails] = useState(null);

  const fetchJobPosts = async () => {
    let data = await db
      .collection(`companies/${companyId}/jobs`)
      .get()
      .catch((error) => {
        console.log("Unable to fetch jobs!", error);
      });

    if (data && data.docs && data.docs.length > 0) {
      let arr = data.docs.map((post) => {
        return { ...post.data(), id: post.id };
      });
      //console.log("Arr", arr);
      setJobPosts(arr);
    } else {
      setJobPosts([]);
    }
  };

  const fetchCompanyDetails = async () => {
    let data = await db
      .collection(`companies`)
      .doc(companyId)
      .get()
      .catch((error) => {
        console.log(error);
      });

    if (data && data.data()) {
      setCompanyDetails(data.data());
    }
  };

  useEffect(() => {
    fetchJobPosts();
    fetchCompanyDetails();
  }, [jobStatusChanged]);

  return (
    <div className="bg-gray-50">
      {companyDetails ? (
        <Header
          view="dashboard"
          type="company"
          roleName={companyDetails.name}
          profilePic={companyDetails.profile_pic}
        />
      ) : null}

      <div className="flex flex-col items-center justify-around w-full">
        <div className="container px-3 py-10 lg:px-20 lg:py-10">
          <div className="flex lg:flex-row flex-col-reverse gap-5 lg:gap-0 lg:justify-end items-center pb-5">
            <div className="flex flex-col lg:flex-row items-center justify-center gap-3">
              {companyDetails && companyDetails.coursesAllowed ? (
                <Link
                  to={`/company/${companyId}/course/create`}
                  className="flex gap-1 items-center justify-center px-3 py-2 bg-purple-600 text-white rounded-lg focus:outline-none hover:bg-purple-800"
                >
                  <MdAddCircleOutline className="text-xl" />
                  <p className="text-sm">Create new course</p>
                </Link>
              ) : null}
              <Link
                to={`/company/${companyId}/job/create`}
                className="flex gap-1 items-center justify-center px-3 py-2 bg-blue-500 text-white rounded-lg focus:outline-none hover:bg-blue-800"
              >
                <MdAddCircleOutline className="text-xl" />
                <p className="text-sm">Create new job</p>
              </Link>
              <Link
                to={`/company/${companyId}/event/create`}
                className="flex gap-1 items-center justify-center px-3 py-2 bg-green-500 text-white rounded-lg focus:outline-none hover:bg-green-800"
              >
                <MdAddCircleOutline className="text-xl" />
                <p className="text-sm">Post new Event/News</p>
              </Link>
              <Link
                to={`/company/${companyId}/managers`}
                className="flex gap-1 items-center justify-center px-3 py-2 bg-yellow-500 text-white rounded-lg focus:outline-none hover:bg-yellow-800"
              >
                <FaUsers className="text-xl" />
                <p className="text-sm">Managers</p>
              </Link>
            </div>
          </div>
        </div>

        {companyDetails && companyDetails.coursesAllowed ? (
          <div className="container px-3 py-10 lg:px-20 lg:py-10">
            <div>
              <p className="text-lg lg:text-xl font-semibold pb-5 flex justify-center lg:justify-start">
                Courses
              </p>
              <div>
                <Courses />
              </div>
            </div>
          </div>
        ) : null}

        <div className="container px-3 py-10 lg:px-20 lg:py-10">
          <p className="text-lg lg:text-xl font-semibold pb-5 flex justify-center lg:justify-start">
            Job Posts
          </p>
          {jobPosts ? (
            jobPosts.length > 0 ? (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 pb-5 ">
                {jobPosts.map((jobPost, index) => (
                  <div key={jobPost.id}>
                    <JobPost
                      data={jobPost}
                      setJobStatusChanged={setJobStatusChanged}
                      companyId={companyId}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center mx-10 my-5">
                <p>No job openings available!</p>
              </div>
            )
          ) : (
            <Loading />
          )}
        </div>

        <div className="container px-3 py-10 lg:px-20 lg:py-10">
          <p className="text-lg lg:text-xl font-semibold pb-5 flex justify-center lg:justify-start">
            Events
          </p>
          <div>
            <Events />
          </div>
        </div>

        <div className="container px-3 py-10 lg:px-20 lg:py-10">
          <p className="text-lg lg:text-xl font-semibold pb-5 flex justify-center lg:justify-start">
            News
          </p>
          <div>
            <News />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
