import React, { useContext, useState } from "react";
import { googleLogo } from "../../assets/images";
import { useHistory, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { doSignInWithGoogle } from "../../firebase";
import AuthContext from "./AuthContext";
import Logo from "../../ITHUBLOGO.PNG";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { doSignInWithEmailAndPassword, doPasswordReset } from "../../firebase";

const SigninSchema = Yup.lazy((values) =>
  Yup.object().shape({
    ...(values.reset
      ? {}
      : {
          password: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        }),
    email: Yup.string().email("Invalid email").required("Required"),
  })
);

function Login() {
  const history = useHistory();
  const { setIsSigningIn, isSigningIn } = useContext(AuthContext);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const handleSignInWithGoogle = () => {
    setIsSigningIn(true);
    doSignInWithGoogle()
      .then(async (user) => {
        console.log("user: ", user);
      })
      .catch((error) => {
        setIsSigningIn(false);
        toast.error(`Couldn't sign in - ${error.message ? error.message : ""}`);
        console.log(error);
      });
  };

  return (
    <div className="h-screen w-screen flex items-center justify-center px-5 bg-gray-800 ">
      <div className="flex bg-white py-12 px-8 sm:p-12 rounded-md">
        <div className="flex flex-col lg:flex-row lg:gap-12 item-center justify-center">
          <div className="justify-center flex">
            <img src={Logo} alt="logo" className="lg:h-64 h-40 self-center" />
          </div>
          <div className="mt-3 w-72">
            <div className="justify-center flex flex-col items-center mb-10">
              <div className="text-2xl font-semibold">Hey there!</div>
              <div className="text-xl font-normal">Login to IT-Hub</div>
            </div>

            <Formik
              validationSchema={SigninSchema}
              initialValues={{ email: "", password: "", reset: false }}
              onSubmit={(values, actions) => {
                const { email, password, reset } = values;
                setIsSigningIn(true);
                if (reset) {
                  doPasswordReset(email)
                    .then(() => {
                      actions.resetForm();
                      setIsSigningIn(false);
                      toast.success(
                        `Reset link has been sent to your email ${email}.`
                      );
                    })
                    .catch((error) => {
                      setIsSigningIn(false);
                      // handleAuthModal({ type: 0, open: false });
                      toast.error(
                        `Something went wrong - ${
                          error.message ? error.message : ""
                        }`
                      );
                      console.log(error);
                    });
                } else {
                  doSignInWithEmailAndPassword(email, password)
                    .then(async (user) => {
                      //setIsSigningIn(false);
                      console.log("User", user);
                    })
                    .catch((error) => {
                      setIsSigningIn(false);
                      // handleAuthModal({ type: 0, open: false });
                      toast.error(
                        `Couldn't sign in - ${
                          error.message ? error.message : ""
                        }`
                      );
                      console.log(error);
                    });
                }
              }}
            >
              {({
                handleSubmit,
                values,
                errors,
                handleChange,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <input
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      type="text"
                      className="form-input border-t-0 border-l-0 border-r-0 border w-full px-3"
                      placeholder="Email Address"
                    />
                    <ErrorMessage
                      component="div"
                      className="text-red-600"
                      name="email"
                    />
                  </div>
                  {!values.reset ? (
                    <div className="mt-6">
                      <div className="relative flex items-center">
                        <input
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                          type={passwordVisibility ? "text" : "password"}
                          className="form-input border-t-0 border-l-0 border-r-0 border w-full px-3"
                          placeholder="Password"
                        />
                        <button
                          className="absolute right-0 m-3"
                          onClick={(e) => {
                            e.preventDefault();
                            setPasswordVisibility((state) => !state);
                          }}
                        >
                          {passwordVisibility ? (
                            <AiOutlineEye className="h-5 w-5 text-gray-600" />
                          ) : (
                            <AiOutlineEyeInvisible className="h-5 w-5 text-gray-600" />
                          )}
                        </button>
                      </div>
                      <ErrorMessage
                        component="div"
                        className="text-red-600"
                        name="password"
                      />
                    </div>
                  ) : null}
                  <div className="mx-3 mt-3 ">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setFieldValue("reset", !values.reset);
                      }}
                    >
                      <div className="font-semibold text-sm">
                        {values.reset
                          ? "Already know your credentials?"
                          : "Forgot Password?"}
                      </div>
                    </button>
                  </div>
                  <div>
                    <button
                      className="flex items-center justify-center rounded-md bg-blue-500 text-white py-2 mt-5 w-full"
                      type="submit"
                    >
                      <div className="font-semibold text-sm items-center gap-2 flex">
                        <div>
                          {isSigningIn ? (
                            <AiOutlineLoading3Quarters className=" animate-spin" />
                          ) : null}
                        </div>
                        <div>
                          {isSigningIn
                            ? "Loading..."
                            : values.reset
                            ? "Get Reset Link"
                            : "Sign In"}
                        </div>
                      </div>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="text-sm text-center pt-1">- OR -</div>

            <button
              className="flex items-center justify-center px-5 rounded-md bg-black text-white py-2 w-full mt-2"
              onClick={handleSignInWithGoogle}
              disabled={isSigningIn}
            >
              <div>
                {isSigningIn ? (
                  <div className="spinner-grow w-6 h-6 mr-3"></div>
                ) : (
                  <img src={googleLogo} alt="logo" className="h-4 w-4 mx-3" />
                )}
              </div>
              <div>
                {isSigningIn ? (
                  <div className="flex gap-2 items-center justify-center">
                    <AiOutlineLoading3Quarters className=" animate-spin" />
                    <p>Please wait...</p>
                  </div>
                ) : (
                  "Continue with Google"
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
