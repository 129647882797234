import React, { useState, useEffect, useContext } from "react";
import firebase from "./firebase";
import App from "./App";
import { FirebaseContext } from "./firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db } from "./firebase";
import AuthContext from "./pages/Login/AuthContext";
import LogRocket from "logrocket";

const LoginProcess = () => {
  const [token, setToken] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const { setIsSigningIn, isSigningIn } = useContext(AuthContext);

  const [pending, setPending] = useState(true);

  const checkIfCompanyManager = async (uid) => {
    console.log("User id", uid);
    let data = await db
      .collectionGroup("managers")
      .where("uid", "==", uid)
      .get()
      .catch((error) => {
        console.log("Checking Manager Error", error);
        setPending(false);
        return false;
      });

    if (data && data.docs[0]) {
      console.log("Check Data", data.docs[0].data().company_id);
      let companyData = await db
        .collection("companies")
        .doc(data.docs[0].data().company_id)
        .get()
        .catch((error) => {
          console.log("Error while checking for managers in company!", error);
        });

      if (companyData && companyData.data()) {
        //console.log("Company Data", companyData.data());
        return {
          company: { ...companyData.data(), company_id: companyData.id },
          manager: data.docs[0].data(),
        };
      }
      return false;
    }
    return false;
  };
  const checkIfUser = async (user) => {
    let data = await db
      .collection("users")
      .doc(user.uid)
      .get()
      .catch(() => toast.error("Error while checking user!"));

    if (data.exists && data && data.data()) {
      if (data.data().type === "company") {
        console.log("Started checking for manager", user.email);
        let companyDetails = await checkIfCompanyManager(user.uid);
        console.log("Company Details", companyDetails);
        if (companyDetails) {
          //console.log("Company Details", data.data());
          return {
            user: data.data(),
            ...companyDetails,
          };
        }
      }
      // if (data.data().type === "task") {
      //   return {
      //     user: data.data(),
      //   };
      // }
      if (data.data().type === "admin") {
        return {
          user: data.data(),
        };
      } else {
        return false;
      }
    } else {
      setPending(false);
      return false;
    }
  };
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      try {
        if (user) {
          console.log("UID", user.uid);
          let finalData = await checkIfUser(user);
          if (finalData) {
            LogRocket.identify(user.uid, {
              name: user.displayName,
              email: user.email,
            });
            setCurrentUser(finalData);
            setPending(false);
            console.log("final Data", finalData);
          } else {
            setIsSigningIn(false);
            setPending(false);
            toast.error("Failed to login");
            firebase.auth().signOut();
          }
        } else {
          setIsSigningIn(false);
          setPending(false);
          setCurrentUser(null);
        }
      } catch (error) {
        console.log("error", error);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  if (pending) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <div className="text-lg font-medium">Loading...</div>
      </div>
    );
  }
  return (
    <FirebaseContext.Provider
      value={{
        firebase,
        currentUser,
        setCurrentUser,
        setToken,
        token,
        setIsSigningIn,
      }}
    >
      <App />
    </FirebaseContext.Provider>
  );
};
export default LoginProcess;
