import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { db, FirebaseContext } from "../../firebase";
import Loading from "../../components/Loading";
import NewsAccordion from "../../components/NewsAccordion";

const ITHubNews = () => {
  const [news, setNews] = useState(null);
  const [newsFetchError, setNewsFetchError] = useState(false);

  const fetchAllNews = async () => {
    let data = await db
      .collectionGroup("news")
      .where("status", "==", true)
      .get()
      .catch((error) => {
        console.log(error);
        setNewsFetchError(true);
        toast.error("Failed to fetch news!");
      });

    if (data && data.docs && data.docs.length > 0) {
      setNews(
        data.docs.map((item) => {
          return {
            ...item.data(),
            id: item.id,
          };
        })
      );
    } else {
      setNews([]);
    }
  };

  useEffect(() => {
    fetchAllNews();
  }, []);

  // if (newsFetchError) {
  //   return (
  //     <div className="flex justify-center m-10 text-lg text-red-600">
  //       <p>Error while fetching news!...</p>
  //     </div>
  //   );
  // }

  if (!news) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (news && news.length === 0) {
    return (
      <div className="flex justify-center m-10 text-xl text-blue-500">
        <p>No news available at the moment!...</p>
      </div>
    );
  }

  return (
    <div>
      <NewsAccordion data={news} viewFromITHUB={true} />
    </div>
  );
};

export default ITHubNews;
