import React from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";

const StatusNavigation = () => {
  let { jobPostId, jobTitle, companyId, courseId, courseTitle } = useParams();

  console.log("Present Situation", jobPostId, courseId);
  const { params } = useRouteMatch(
    jobPostId
      ? "/company/:companyId/job/:jobTitle/:jobPostId/applicants/:tab"
      : "/company/:companyId/course/:courseId/:courseTitle/enrollments/:tab"
  );

  let companyDictionary = [
    { value: "all", label: "All Applicants" },
    { value: "hired", label: "Hired" },
    { value: "shortlist", label: "Shortlisted" },
    { value: "rejected", label: "Rejected" },
  ];

  let courseDictionary = [
    { value: "all", label: "All Enrollments" },
    { value: "approve", label: "Approved" },
    { value: "reject", label: "Rejected" },
  ];

  let path = params.tab;
  if (jobPostId) {
    return (
      <div className="flex lg:justify-center lg:gap-16 justify-evenly py-1 mb-2">
        {companyDictionary.map(({ value, label }) => {
          return (
            <Link
              to={`/company/${companyId}/job/${jobTitle}/${jobPostId}/applicants/${value}`}
              className={`${
                path === value ? "border-b-4" : ""
              } border-red-500 py-3 px-2`}
            >
              <p className="text-sm lg:text-base">{label}</p>
            </Link>
          );
        })}
      </div>
    );
  }

  if (courseId) {
    return (
      <div className="flex lg:justify-center lg:gap-16 justify-evenly py-1 mb-2">
        {courseDictionary.map(({ value, label }) => {
          return (
            <Link
              to={`/company/${companyId}/course/${courseId}/:${courseTitle}/enrollments/${value}`}
              className={`${
                path === value ? "border-b-4" : ""
              } border-red-500 py-3 px-2`}
            >
              <p className="text-sm lg:text-base">{label}</p>
            </Link>
          );
        })}
      </div>
    );
  }
};

export default StatusNavigation;
