import { isAfter } from "date-fns";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import { toast } from "react-toastify";
import * as Yup from "yup";
import CustomDatePicker from "../../../components/CustomDatePicker";
import Header from "../../../components/Header";
import Loading from "../../../components/Loading";
import { db, FirebaseContext } from "../../../firebase";
import ImageDropzone from "../../../components/ImageDropZone";
import CompanyLogoPlaceholder from "../../../PlaceHolder.png";
import RequiredAsterisk from "../../../components/RequiredAsterisk";

const schema = Yup.lazy((values) => {
  return Yup.object().shape({
    jobTitle: Yup.string().nullable().required("Required!"),
    jobType: Yup.string().nullable(),
    onlineInterview: Yup.boolean().nullable(),
    numberOfOpenings: Yup.number().nullable().required("Required!"),
    startsAt: Yup.date().nullable().required("Required!"),
    endsAt: Yup.date().nullable().required("Required!"),
    aboutUs: Yup.string().nullable().required("Required"),
    jobDescription: Yup.string().nullable().required("Required!"),
    minCTC: Yup.number().nullable().required("Required"),
    maxCTC: Yup.number().nullable().required("Required"),
    ctcBreakup: Yup.string().nullable(),
    fixedPay: Yup.number().nullable().required("Required!"),
    variablePay: Yup.number().nullable().required("Required!"),
    otherIncentives: Yup.number().nullable().required("Required!"),
    perks: Yup.array().nullable(),
    newQuestions: Yup.array().nullable(),
  });
});

const CreateEditJobPost = () => {
  const { currentUser } = useContext(FirebaseContext);
  let { jobPostId, companyId } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const [jobPostDetailsError, setJobPostDetailsError] = useState(false);
  const [addingNewJobPost, setAddingNewJobPost] = useState(false);
  const [jobAdded, setJobAdded] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);

  const [skills, setSkills] = useState({});
  const [value, setValue] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const fetchJobDetail = async () => {
    let data = await db
      .collection(`companies/${companyId}/jobs`)
      .doc(jobPostId)
      .get()
      .catch(() => {
        toast.error("Error while fetching Job Post Info!");
      });

    if (data && data.data()) {
      setJobDetails(data.data());
      let currentSkills = data.data().skills;
      setSkills(
        currentSkills
          ? currentSkills.reduce((prev, cur) => ({ ...prev, [cur]: true }), {})
          : {}
      );
    } else {
      toast.error("Job Post Data not available");
      setJobPostDetailsError(true);
    }
  };

  const fetchCompanyDetails = async () => {
    let data = await db
      .collection(`companies`)
      .doc(companyId)
      .get()
      .catch((error) => {
        console.log(error);
      });

    if (data && data.data()) {
      setCompanyDetails(data.data());
      //console.log("Company Details", companyDetails);
    }
  };

  useEffect(() => {
    if (jobPostId) {
      fetchJobDetail();
    }
    fetchCompanyDetails();
  }, []);

  //console.log("New Job post", currentUser);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleDelete = (item) => {
    setSkills((prevSkill) => {
      let newSkill = {
        ...prevSkill,
      };
      delete newSkill[item];
      return newSkill;
    });
  };

  const handleEnterKey = (e) => {
    if (["Enter", ","].includes(e.key)) {
      e.preventDefault();

      let temp = value.trim();
      if (skills[temp]) {
        toast.error("You have already added this skill.");
      } else if (typeof skills === "object" && temp) {
        setSkills({ ...skills, [temp]: true });
      } else {
        setSkills({ [temp]: true });
      }
      setValue("");
    }
  };

  if (jobPostDetailsError) {
    return (
      <div className="flex justify-center m-10">
        <p>Failed to load job post details...</p>
      </div>
    );
  }

  if (!companyDetails) {
    return <Loading />;
  }

  return (
    <div>
      <Header view="jobPost" />

      {!jobPostId || jobDetails ? (
        <div className="lg:px-28 lg:pt-10">
          <Formik
            initialValues={
              jobDetails
                ? {
                    status: jobDetails.status,
                    jobTitle: jobDetails.jobTitle,
                    jobType: jobDetails.jobType,
                    onlineInterview: jobDetails.onlineInterview,
                    numberOfOpenings: jobDetails.numberOfOpenings,
                    jobDescription: jobDetails.jobDescription,
                    startsAt: new Date(jobDetails.startsAt.seconds * 1000),
                    endsAt: new Date(jobDetails.endsAt.seconds * 1000),
                    aboutUs: jobDetails.aboutUs,
                    minCTC: jobDetails.minCTC,
                    maxCTC: jobDetails.maxCTC,
                    ctcBreakup: jobDetails.ctcBreakup,
                    fixedPay: jobDetails.fixedPay,
                    variablePay: jobDetails.variablePay,
                    otherIncentives: jobDetails.otherIncentives,
                    perks: jobDetails.perks,
                    profile_pic: jobDetails.profile_pic
                      ? jobDetails.profile_pic
                      : null,
                  }
                : {
                    status: false,
                    jobTitle: null,
                    jobType: "remote",
                    onlineInterview: false,
                    numberOfOpenings: null,
                    jobDescription: null,
                    startsAt: null,
                    endsAt: null,
                    aboutUs: companyDetails ? companyDetails.aboutUs : null,
                    minCTC: null,
                    maxCTC: null,
                    ctcBreakup: "lpa",
                    fixedPay: null,
                    variablePay: null,
                    otherIncentives: null,
                    perks: [],
                    profile_pic: companyDetails
                      ? companyDetails.profile_pic
                      : null,
                  }
            }
            validationSchema={schema}
            onSubmit={(values) => {
              console.log("Values", values);
              let perksDictionary = {
                "5_days_in_a_week": "5 days in a week",
                informal_dress_code: "Informal Dress Code",
                health_insurance: "Health Insurance",
                life_insurance: "Life Insurance",
                transport_facility: "Transportation Facility",
                free_snacks: "Free snacks and beverages",
              };
              let finalData = {
                ...values,
                perks:
                  values.perks.length > 0
                    ? values.perks.map((perk) => perk)
                    : [],
                company_id: companyId,
                company_name: companyDetails.name,
                company_profile_pic: companyDetails.profile_pic
                  ? companyDetails.profile_pic
                  : "",
                city: companyDetails.address,
                skills: Object.keys(skills ? skills : []),
                time: new Date(),
              };

              //console.log("Final Data", finalData);
              if (!jobPostId) {
                if (companyDetails) {
                  setAddingNewJobPost(true);
                  db.collection(`companies/${companyId}/jobs`)
                    .add(finalData)
                    .then(() => {
                      toast.success("New job post created successfully!");
                      setAddingNewJobPost(false);
                      setJobAdded(true);
                    })
                    .catch((error) => {
                      console.log(error);
                      setAddingNewJobPost(false);

                      toast.error("Error occured while creating new job post!");
                    });
                } else {
                  toast.error("Waiting for company details...");
                }
              } else {
                if (companyDetails) {
                  console.log(finalData);
                  setAddingNewJobPost(true);
                  db.collection(`companies/${companyId}/jobs`)
                    .doc(jobPostId)
                    .update(finalData)
                    .then(() => {
                      toast.success("Job post edited successfully!");
                      setAddingNewJobPost(false);
                    })
                    .catch((error) => {
                      console.log(error);
                      setAddingNewJobPost(false);

                      toast.error("Error occured while editing job post!");
                    });
                } else {
                  toast.error("Waiting for company details...");
                }
              }
            }}
          >
            {({ values, errors, setFieldValue }) => {
              return (
                <Form className="lg:w-10/12 mx-auto px-2 py-5 lg:px-0 lg:py-0">
                  <div className="flex justify-end items-center gap-4">
                    <p className="font-medium text-lg">Job Status</p>
                    <Switch
                      type="checkbox"
                      name="status"
                      onChange={(checked) => {
                        setFieldValue("status", checked ? true : false);
                      }}
                      checked={values.status}
                    />
                  </div>
                  <div className="flex flex-col gap-10">
                    <div>
                      <p className="text-xl font-medium">Job details</p>
                      <div className="p-5 mt-2 flex flex-col gap-4 rounded-lg border">
                        <div>
                          <div className="flex gap-1 items-center">
                            <p>Job title</p>
                            <RequiredAsterisk />
                          </div>
                          <Field
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="e.g. Software Engineer Trainee"
                            name="jobTitle"
                          />
                          <ErrorMessage
                            name="jobTitle"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>

                        <div>
                          <div className="flex gap-1 items-center">
                            <p>Number of openings</p>
                            <RequiredAsterisk />
                          </div>

                          <Field
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="e.g. 3"
                            name="numberOfOpenings"
                          />
                          <ErrorMessage
                            name="numberOfOpenings"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>
                        <div>
                          <div className="flex gap-1 items-center">
                            <p>Job description</p>
                            <RequiredAsterisk />
                          </div>

                          <Field
                            as="textarea"
                            rows={6}
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="Key responsibilities"
                            name="jobDescription"
                          />
                          <ErrorMessage
                            name="jobDescription"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>
                        <div className="flex flex-col lg:flex-row justify-between lg:gap-10">
                          <div className="w-full">
                            <div className="flex gap-1 items-center">
                              <p>Job/Internship Start Date</p>
                              <RequiredAsterisk />
                            </div>

                            <Field
                              name="startsAt"
                              className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                              component={(props) => (
                                <CustomDatePicker
                                  {...props}
                                  format="do MMMM, yyyy hh:mm a"
                                  showTimeSelect={true}
                                  padded={true}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="startsAt"
                              render={(msg) => (
                                <div className="text-red-600 text-sm">
                                  {msg}
                                </div>
                              )}
                            />
                          </div>

                          <div className="w-full">
                            <div className="flex gap-1 items-center">
                              <p>Application Close Date</p>
                              <RequiredAsterisk />
                            </div>
                            <Field
                              name="endsAt"
                              className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                              component={(props) => (
                                <CustomDatePicker
                                  {...props}
                                  format="do MMMM, yyyy hh:mm a"
                                  showTimeSelect={true}
                                  padded={true}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="endsAt"
                              render={(msg) => (
                                <div className="text-red-600 text-sm">
                                  {msg}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p className="text-xl font-medium">{"Salary & perks"}</p>
                      <div className="p-5 mt-2 flex flex-col gap-4 rounded-lg border">
                        <div>
                          <div className="flex gap-1 items-center">
                            <p>CTC</p>
                            <RequiredAsterisk />
                          </div>
                          <div className="flex items-center gap-4">
                            <div className="w-1/3 flex-col">
                              <Field
                                className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                                placeholder="e.g. 5.2"
                                name="minCTC"
                              />
                              <ErrorMessage
                                name="minCTC"
                                render={(msg) => (
                                  <div className="text-red-600 text-sm">
                                    {msg}
                                  </div>
                                )}
                              />
                            </div>
                            to
                            <div className="w-1/3 flex-col">
                              <Field
                                className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                                placeholder="e.g. 6.5"
                                name="maxCTC"
                              />{" "}
                              <ErrorMessage
                                name="maxCTC"
                                render={(msg) => (
                                  <div className="text-red-600 text-sm">
                                    {msg}
                                  </div>
                                )}
                              />
                            </div>
                            LPA
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <p>CTC breakup</p>
                          <p className="text-sm text-gray-500 font-medium">
                            Transparent CTC breakup attracts top candidates
                          </p>
                          <div className="flex gap-2">
                            <div className="flex gap-2 items-center">
                              <Field
                                type="radio"
                                name="ctcBreakup"
                                value="percent"
                              />
                              In %
                            </div>
                            <div className="flex gap-2 items-center">
                              <Field
                                type="radio"
                                name="ctcBreakup"
                                value="lpa"
                              />
                              In LPA
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <div className="flex gap-1 items-center">
                            <p>Fixed pay</p>
                            <RequiredAsterisk />
                          </div>

                          <p className="text-sm text-gray-500 font-medium">
                            Fixed pay is the fixed component of the CTC
                          </p>
                          <div className="flex items-center gap-4">
                            <Field
                              className="px-3 py-2 border w-1/3 rounded my-1 focus:outline-none"
                              placeholder="e.g. 5.2"
                              name="fixedPay"
                            />
                            <ErrorMessage
                              name="fixedPay"
                              render={(msg) => (
                                <div className="text-red-600 text-sm">
                                  {msg}
                                </div>
                              )}
                            />
                            LPA
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <div className="flex gap-1 items-center">
                            <p>Variable pay</p>
                            <RequiredAsterisk />
                          </div>

                          <p className="text-sm text-gray-500 font-medium">
                            Variable pay includes performance based cash
                            incentives and bonuses
                          </p>
                          <div className="flex items-center gap-4">
                            <Field
                              className="px-3 py-2 border w-1/3 rounded my-1 focus:outline-none"
                              placeholder="e.g. 1.2"
                              name="variablePay"
                            />
                            <ErrorMessage
                              name="variablePay"
                              render={(msg) => (
                                <div className="text-red-600 text-sm">
                                  {msg}
                                </div>
                              )}
                            />
                            LPA
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <div className="flex gap-1 items-center">
                            <p>Other Incentives</p>
                            <RequiredAsterisk />
                          </div>

                          <p className="text-sm text-gray-500 font-medium">
                            Other incentives may include joining bonus,
                            relocation assistance, gratuity, etc.
                          </p>
                          <div className="flex items-center gap-4">
                            <Field
                              className="px-3 py-2 border w-1/3 rounded my-1 focus:outline-none"
                              placeholder="e.g. 0.4"
                              name="otherIncentives"
                            />
                            <ErrorMessage
                              name="otherIncentives"
                              render={(msg) => (
                                <div className="text-red-600 text-sm">
                                  {msg}
                                </div>
                              )}
                            />
                            LPA
                          </div>
                        </div>

                        <div>
                          <p>
                            Perks
                            <span className="text-sm text-gray-500 ml-1">
                              (Optional)
                            </span>
                          </p>
                          <div className="grid grid-cols-2">
                            <div className="flex gap-2 items-center pt-3">
                              <Field
                                type="checkbox"
                                name="perks"
                                value="5_days_in_a_week"
                              />
                              <p className="text-gray-500">5 days in week</p>
                            </div>
                            <div className="flex gap-2 items-center pt-3">
                              <Field
                                type="checkbox"
                                name="perks"
                                value="transport_facility"
                              />
                              <p className="text-gray-500">
                                Cab/Transportation facility
                              </p>
                            </div>
                            <div className="flex gap-2 items-center pt-3">
                              <Field
                                type="checkbox"
                                name="perks"
                                value="informal_dress_code"
                              />
                              <p className="text-gray-500">
                                Informal dress code
                              </p>
                            </div>
                            <div className="flex gap-2 items-center pt-3">
                              <Field
                                type="checkbox"
                                name="perks"
                                value="health_insurance"
                              />
                              <p className="text-gray-500">Health Insurance</p>
                            </div>
                            <div className="flex gap-2 items-center pt-3">
                              <Field
                                type="checkbox"
                                name="perks"
                                value="free_snacks"
                              />
                              <p className="text-gray-500">
                                Free snacks and beverages
                              </p>
                            </div>
                            <div className="flex gap-2 items-center pt-3">
                              <Field
                                type="checkbox"
                                name="perks"
                                value="life_insurance"
                              />
                              <p className="text-gray-500">Life Insurance</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pb-5">
                      <p className="text-xl font-medium">Skills & assessment</p>
                      <div className="p-5 mt-2 flex flex-col gap-4 rounded-lg border">
                        <div>
                          <p>Skills required</p>
                          <input
                            value={value}
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="Eg. React"
                            onChange={(e) => handleChange(e)}
                            onKeyDown={(e) => handleEnterKey(e)}
                          />
                          {typeof skills === "object" ? (
                            <div className="flex gap-5 flex-wrap">
                              {Object.keys(skills).map((skill) => (
                                <div
                                  className="bg-gray-300 px-3 py-2 rounded-full gap-2 flex items-center justify-between"
                                  key={skill}
                                >
                                  <p className="font-medium flex flex-wrap">
                                    {skill}
                                  </p>
                                  <button
                                    type="button"
                                    className="bg-white rounded-full h-4 w-4 flex items-center justify-center focus:outline-none"
                                    onClick={() => handleDelete(skill)}
                                  >
                                    &times;
                                  </button>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <div className="flex gap-1 items-center">
                            <p>About the company</p>
                            <RequiredAsterisk />
                          </div>

                          <Field
                            as="textarea"
                            rows={6}
                            className="px-3 py-2 border w-full rounded my-1 focus:outline-none"
                            placeholder="Write something about your company..."
                            name="aboutUs"
                          />
                          <ErrorMessage
                            name="aboutUs"
                            render={(msg) => (
                              <div className="text-red-600 text-sm">{msg}</div>
                            )}
                          />
                        </div>
                        <div>
                          <p>Company Logo</p>
                          <div className="flex justify-center">
                            <ImageDropzone
                              image_url={values.profile_pic}
                              name="profile_pic"
                              setFieldValue={setFieldValue}
                              setIsUploading={setIsUploading}
                              path={
                                jobPostId
                                  ? `companyJobPics/${jobPostId}/eventLogo.png`
                                  : `companyJobPics/newPics/${new Date()
                                      .getTime()
                                      .toString()}.png`
                              }
                              className="lg:w-1/2 w-full lg:h-60 h-60 flex justify-center items-center cursor-pointer"
                            />
                          </div>
                        </div>

                        <div className="flex justify-end items-center">
                          <div className="mr-10">
                            {Object.keys(errors).length > 0 ? (
                              <p className="text-red-500">
                                Please fill all the required details
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>

                          {!jobPostId ? (
                            !jobAdded ? (
                              <button
                                type="submit"
                                disabled={addingNewJobPost || isUploading}
                                className={`px-6 py-2 ${
                                  !isUploading
                                    ? "bg-blue-600"
                                    : "bg-gray-600 cursor-not-allowed"
                                } rounded-lg text-white focus:outline-none`}
                              >
                                {addingNewJobPost ? (
                                  <div className="flex gap-2 items-center justify-center">
                                    <AiOutlineLoading3Quarters className=" animate-spin" />
                                    <p>Submitting...</p>
                                  </div>
                                ) : isUploading ? (
                                  <div className="flex gap-2 items-center justify-center">
                                    <AiOutlineLoading3Quarters className=" animate-spin" />
                                    <p>Please Wait...</p>
                                  </div>
                                ) : (
                                  "Submit"
                                )}
                              </button>
                            ) : (
                              <div className="px-6 py-2 bg-green-300 rounded-lg text-white focus:outline-none">
                                <p>Job Created</p>
                              </div>
                            )
                          ) : (
                            <button
                              type="submit"
                              disabled={addingNewJobPost}
                              className="px-6 py-2 bg-blue-600 rounded-lg text-white focus:outline-none"
                            >
                              {addingNewJobPost ? (
                                <div className="flex gap-2 items-center justify-center">
                                  <AiOutlineLoading3Quarters className=" animate-spin" />
                                  <p>Submitting...</p>
                                </div>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default CreateEditJobPost;
